import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type SpinnerState = {
  showSpinner: boolean;
};

const name = 'system';
const initialState: SpinnerState = {
  showSpinner: false,
};

export const {
  reducer,
  actions: { toggleSpinner },
} = createSlice({
  name,
  initialState,
  reducers: {
    toggleSpinner(state = initialState, action: PayloadAction<boolean>) {
      state.showSpinner = action.payload;
    },
  },
});

let rootSelector: RootSelector<SpinnerState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<SpinnerState> = selector => {
  rootSelector = selector;
};

export const getShowSpinner = (state: RootState) =>
  rootSelector(state)?.showSpinner;
