import { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';

import { getStyles, getClientRect } from '#/utils/getElementStyle';
import { getRelativePixel } from '#/utils/relativePxValues';
import { usePrevious, useAppDispatch, useAppSelector } from '#/hooks';
import redux from '#/redux/modules';

import styles from './scroll.scss';

const {
  scroll: { getFocusedId, clearScrollState, setScrolled },
  system: { getIsLowEndDevice },
} = redux;

// Use this for devices with safe margin (such as X1s)
const SAFE_MARGIN = 0;

type Props = {
  id: string;
  preserveId?: boolean;
  extraPush?: number;
  children: React.ReactNode;
};

const Scroll = ({ id, extraPush = 0, preserveId, children }: Props) => {
  const dispatch = useAppDispatch();
  const focusedId = useAppSelector(getFocusedId(id));
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);
  const [yAxis, setYAxis] = useState(0);
  const focusedElementRef = useRef<HTMLElement | null>(null);
  const prevFocusedId = usePrevious(focusedId ?? '');
  const scrollRef = useRef<HTMLDivElement>(null);
  const viewPortRef = useRef<HTMLDivElement>(null);

  const getMinScroll = useCallback(() => {
    return 0;
  }, []);

  /**
   * Retrieves the maximum scroll using the wrapper's height
   * @returns The maximum value for translateY to reach
   */
  const getMaxScroll = useCallback(() => {
    const focusedElement = focusedElementRef.current;

    if (!scrollRef.current || !viewPortRef.current) {
      return 0;
    }

    const visibleHeight = window.innerHeight;
    const {
      height: fullHeight,
      marginBottom: scrollMarginBottom = 0,
      paddingBottom: scrollPaddingBottom = 0,
    } = getClientRect(scrollRef.current, true);
    const { top: containerTop } = getClientRect(viewPortRef.current);
    const { marginBottom: elementMarginBottom } = getStyles(focusedElement!);
    const heightDifference = fullHeight - visibleHeight;

    return (
      heightDifference +
      containerTop +
      SAFE_MARGIN -
      (scrollMarginBottom + scrollPaddingBottom) +
      elementMarginBottom
    );
  }, []);

  /**
   * Returns the new Y axis of the scroll down
   * The new axis will be considering the next element, ending up at the bottom of the screen
   * @returns the value of new Y axis
   */
  const getScrollDownJustAxis = () => {
    const focusedElement = focusedElementRef.current;
    const visibleHeight = window.innerHeight;
    const {
      top,
      marginBottom = 0,
      height,
    } = getClientRect(focusedElement!, true);
    const elementBottom = top + height + marginBottom;

    return Math.ceil(
      yAxis - (elementBottom + getRelativePixel(extraPush) - visibleHeight),
    );
  };

  /**
   * Sets the new Y axis for a scroll up
   * Based on the current Y axis, we rest it to the focus component's  top position
   * If there's a header visible, we compensate that height
   */
  const scrollUp = () => {
    const focusedElement = focusedElementRef.current;

    const minScroll = getMinScroll();
    const { top, marginTop = 0 } = getClientRect(focusedElement!, true);
    const elementTop = top + marginTop;

    const newYAxis = Math.ceil(
      yAxis - elementTop + getRelativePixel(extraPush),
    );

    setYAxis(newYAxis < minScroll ? newYAxis : 0);
  };

  /**
   * Sets the new Y axis for a scroll down
   * The new axis is calculated using the focus element's top minus the scroll wrapper's top
   * It also validates that the new axis does not supass the maximum scroll
   *
   */
  const scrollDown = () => {
    const maxScroll = getMaxScroll();
    const newYAxis = getScrollDownJustAxis();

    setYAxis(-newYAxis > maxScroll ? -maxScroll : newYAxis);
  };

  useEffect(() => {
    const focusedElement = document.getElementById(focusedId);

    if (!focusedId || !focusedElement) {
      return;
    }

    if (focusedId !== prevFocusedId) {
      focusedElementRef.current = focusedElement;

      const {
        top,
        bottom,
        marginTop = 0,
        marginBottom = 0,
      } = getClientRect(focusedElement, true);
      const elementTop = top + marginTop;
      const elementBottom = bottom + marginBottom;
      const visibleHeight = window.innerHeight;

      if (elementTop < 0) {
        scrollUp();
      } else if (elementBottom > visibleHeight - SAFE_MARGIN) {
        scrollDown();
      }
    }
  }, [focusedId]);

  useEffect(() => {
    dispatch(setScrolled(yAxis < 0));
  }, [yAxis]);

  useEffect(() => {
    return () => {
      if (!preserveId) {
        dispatch(clearScrollState(id));
      }
    };
  }, []);

  return (
    <div ref={viewPortRef} className={styles.scrollContainer}>
      <div
        ref={scrollRef}
        className={classNames({
          [styles.scroll]: !isLowEndDevice,
        })}
        style={{ transform: `translateY(${yAxis}px)` }}
      >
        {children}
      </div>
    </div>
  );
};

export default Scroll;
