import { useCallback, useEffect } from 'react';

import { preventDefaultBack } from '#/redux/modules/nav';

import useAppDispatch from './useAppDispatch';

function usePreventDefaultBack() {
  const dispatch = useAppDispatch();

  const preventBack = useCallback(prevent => {
    dispatch(preventDefaultBack(prevent));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(preventDefaultBack(false));
    };
  }, []);

  return preventBack;
}

export default usePreventDefaultBack;
