import { combineReducers } from 'redux';

import modules from './modules';
import { extractReducers, registerModules } from './utils/reduxUtils';

type ReduxModules = typeof modules;
type ModuleNames = keyof ReduxModules;
type Reducers = {
  [k in ModuleNames]: ReduxModules[k]['reducer'];
};

const MODULES_MOUNT_POINT = 'modules';

// Register the modules that we'd like to use
// and update their root selectors with the modules
// mount point
registerModules(modules, MODULES_MOUNT_POINT);

// Extract the reducers from the redux modules
// so we can combine them into one root reducer
// for the application.
const moduleReducers: Reducers = extractReducers<ReduxModules>(modules);

// Export the root reducer
export const rootReducer = combineReducers({
  [MODULES_MOUNT_POINT]: combineReducers(moduleReducers),
});

export type RootState = ReturnType<typeof rootReducer>;
