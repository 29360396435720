import { HashRouter, MemoryRouter } from 'react-router-dom';
import { createHashHistory, createMemoryHistory } from 'history';

let appHistory;
let Router: React.ElementType;

if (typeof global.document === 'undefined' || __TEST__) {
  appHistory = createMemoryHistory();
  Router = MemoryRouter;
} else {
  appHistory = createHashHistory();
  Router = HashRouter;
}

export default {
  appHistory,
  Router,
};
