const padZero = (n: number) => {
  return n < 10 ? `0${n}` : n;
};

const formatTime = (timeInSeconds = 0) => {
  if (typeof timeInSeconds !== 'number' || timeInSeconds < 0) {
    timeInSeconds = 0;
  }

  const timeArray = [];

  timeArray.push(padZero(Math.floor(timeInSeconds / 3600)));
  timeArray.push(padZero(Math.floor((timeInSeconds / 60) % 60)));
  timeArray.push(padZero(Math.floor(timeInSeconds % 60)));

  return timeArray.join(':');
};

export default formatTime;
