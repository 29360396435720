import classNames from 'classnames';

import { withFocus } from '@accedo/vdkweb-navigation';

import Check from '#/static/images/icons/check.svg';

import styles from './parentalControl.scss';

type Props = {
  displayText?: string;
  displayDescription?: string;
  checkedIndex?: number;
};

const ParentalControlItem = ({
  nav: { id },
  isFocused,
  checkedIndex,
  displayText,
  displayDescription,
}: Props & WithFocusProps) => {
  return (
    <div
      id={id}
      tabIndex={-1}
      className={classNames(styles.parentalItem, {
        [styles.parentalItemFocused]: isFocused,
      })}
    >
      <div className={styles.textContainer}>
        <span className={styles.displayText}>{displayText}</span>
        <span className={styles.displayDescription}>{displayDescription}</span>
      </div>

      {`${'PARENTAL_CONTROL_ITEM'}${checkedIndex}` === id && (
        <img
          className={styles.checked}
          src={Check}
          alt="Checked"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default withFocus(ParentalControlItem);
