/*
 * Export the Redux modules that you would like to
 * use with state management.
 *
 * This will be used by redux/reducer.js to walkthrough
 * all registered modules and add root selectors to them for
 * providing the base state of each module.
 */

/* External modules */
import * as player from '@accedo/vdkweb-player/lib/redux';

/* TV modules */
import { pageRedux } from '@accedo/vdkweb-tv-ui';

/* Internal modules */
import * as menu from './menu';
import * as modal from './modal';
import * as status from './status';
import * as config from './config';
import * as scroll from './scroll';
import * as spinner from './spinner';
import * as appPlayer from './appPlayer';
import * as system from './system';
import * as nav from './nav';
import * as parentalControl from './parentalControl';
import * as movies from './movies';
import * as series from './series';

export default {
  config,
  status,
  menu,
  modal,
  player,
  pageRedux,
  scroll,
  appPlayer,
  spinner,
  system,
  nav,
  parentalControl,
  movies,
  series,
};
