import { MAIN_MENU } from '#/utils/navigationMap';

const { MENU_CONTAINER } = MAIN_MENU;

function useToHeaderNav(nav: any) {
  return {
    ...nav,
    nextleft: MENU_CONTAINER,
  };
}

export default useToHeaderNav;
