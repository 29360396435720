import _omit from 'lodash/omit';

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type ScrollState = {
  isScrolled: boolean;
  scrollState: {
    [key: string]: string;
  };
};

type UpdateScrollPayload = {
  scrollId: string;
  focusedId: string;
};

const name = 'scroll';

const initialState: ScrollState = { isScrolled: false, scrollState: {} };

export const {
  reducer,
  actions: { setScrolled, setFocusedId, clearScrollState },
} = createSlice({
  name,
  initialState,
  reducers: {
    setScrolled(state, { payload }: PayloadAction<boolean>) {
      state.isScrolled = payload;
    },
    setFocusedId(state, { payload }: PayloadAction<UpdateScrollPayload>) {
      const { scrollId, focusedId } = payload;

      state.scrollState[scrollId] = focusedId;
    },
    clearScrollState(state, { payload }: PayloadAction<string>) {
      state.scrollState = _omit(state.scrollState, [payload]);
    },
  },
});

let rootSelector: RootSelector<ScrollState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<ScrollState> = selector => {
  rootSelector = selector;
};

export const getIsScrolled = (state: RootState) =>
  rootSelector(state).isScrolled;
export const getFocusedId = (scrollId: string) => (state: RootState) =>
  rootSelector(state).scrollState[scrollId];
