import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppServices } from '@accedo/accedo-one';

import type { RootState } from '#/redux/reducer';
import {
  configUrl,
  contentFeedUrl,
} from '#/services/directPublisher/directPublisher';

type ThunkApi = {
  extra: {
    accedoOne: AppServices | null;
  };
};

type ConfigResponse = {
  config?: any;
  contentFeed?: any;
};

type ConfigState = {
  error?: object;
  config?: any;
  contentFeed?: any;
  hasError: boolean;
  isFetching: boolean;
};

const name = 'config';

const initialState: ConfigState = {
  isFetching: false,
  hasError: false,
};

let rootSelector: RootSelector<ConfigState> = (state: any) => state[name];

export const fetchConfig = createAsyncThunk<ConfigResponse, void, ThunkApi>(
  'fetchConfig',
  async (_, { extra }) => {
    const { accedoOne } = extra;

    if (!accedoOne) {
      return { config: null };
    }

    const feed = await fetch(contentFeedUrl);
    const contentFeed: any = await feed.json();

    const data = await fetch(configUrl);
    const config: any = await data.json();

    return { config, contentFeed };
  },
  {
    condition: (_, { getState }) => {
      const state = rootSelector(getState());

      if (state.isFetching || state.config) {
        return false;
      }

      return true;
    },
  },
);

export const { reducer } = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchConfig.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      fetchConfig.rejected,
      (state, action: PayloadAction<any>) => {
        const { error } = action.payload;

        state.isFetching = false;
        state.hasError = true;
        state.error = error;
      },
    );

    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      if (state.isFetching) {
        state.isFetching = false;
      }

      state.contentFeed = action.payload.contentFeed;
      state.config = action.payload.config;
    });
  },
});

export const setRootSelector: SetRootSelector<ConfigState> = selector => {
  rootSelector = selector;
};

export const getConfig = (state: RootState) => rootSelector(state).config;
export const getContentFeed = (state: RootState) =>
  rootSelector(state).contentFeed;
export const getIsFetching = (state: RootState) =>
  rootSelector(state).isFetching;
export const getIsGeoBlocked = (state: RootState) =>
  rootSelector(state)?.config?.accedoOne?.geoBlocking;
export const getIsMaintenanceActive = (state: RootState) =>
  rootSelector(state)?.config?.accedoOne?.maintenanceMode;
