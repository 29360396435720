const cookieName = 'vizio-smartcast';
const network = {
  polling: {
    interval: 30,
    url: 'https://www.google.com',
  },
};

if (typeof document !== 'undefined') {
  // the keydown event will be handled by XDK
  document.onkeydown = event => {
    event.preventDefault();
  };
}

const COOKIE_ONLY = {
  cookieName,
};

const COOKIE_AND_POLLER = {
  ...COOKIE_ONLY,
  network,
};

const CONFIG = {
  devices: {
    packages: [
      {
        id: 'vizio-smartcast',
        detection: () =>
          import('@accedo/xdk-device-vizio-smartcast-contrib/esm/detection.js'),
        defaultConfig: () =>
          import(
            '@accedo/xdk-device-vizio-smartcast-contrib/esm/defaultConfig.js'
          ),
        DevicePackage: () =>
          import(
            '@accedo/xdk-device-vizio-smartcast-contrib/esm/DevicePackage.js'
          ),
      },
      {
        id: 'workstation',
        detection: () =>
          import('@accedo/xdk-device-workstation/esm/detection.js'),
        defaultConfig: () =>
          import('@accedo/xdk-device-workstation/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-workstation/esm/DevicePackage.js'),
      },
    ],
    details: {
      workstation: COOKIE_AND_POLLER,
      'vizio-smartcast': COOKIE_ONLY,
    },
  },
  logging: {
    level: -1,
  },
};

export default CONFIG;
