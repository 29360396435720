import { useEffect } from 'react';
import Helmet from 'react-helmet';

import config from '#/config';
import { useAppDispatch } from '#/hooks';
import { appStart, appQuit } from '#/redux/modules/lifecycle';

import AppContent from './AppContent';

const { title, titleTemplate, meta } = config.app.head;

type Props = {
  children: React.ReactNode;
};

// An application shell for listening to mount
// events and trigger appropriate lifecycle
// actions for your app. These would typically
// be used for analytics and similar.
const AppShell = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const importAndLoadXdk = async () => {
      // xdk-core export both using default export and named export,
      // and xdk.load() in the XDK4 Getting Started guide
      // is using the default export of xdk-core.
      const { default: xdk } = await import('@accedo/xdk-core');

      await xdk.load();
    };

    importAndLoadXdk();
    dispatch(appStart());

    return () => {
      dispatch(appQuit());
    };
  }, []);

  return (
    <>
      <Helmet
        defaultTitle={title}
        titleTemplate={titleTemplate}
        meta={meta}
        style={[
          {
            type: 'text/css',
            cssText: `
              body, html {
                  width: 100%;
                  height: 100%;
              }
          `,
          },
        ]}
      />
      <AppContent>{children}</AppContent>
    </>
  );
};

export default AppShell;
