import { useCallback, useRef } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';

import { setFocusedId } from '#/redux/modules/scroll';

import useAppDispatch from './useAppDispatch';

function useUpdateScroll(scrollId: string) {
  const dispatch = useAppDispatch();
  const currentIdRef = useRef('');

  const updateScroll = useCallback(
    (explicitId?: string) => {
      const focusedId = explicitId || focusManager.getCurrentFocus();

      if (focusedId === currentIdRef.current) {
        return;
      }

      dispatch(
        setFocusedId({
          scrollId,
          focusedId,
        }),
      );

      currentIdRef.current = focusedId;
    },
    [scrollId, dispatch],
  );

  return updateScroll;
}

export default useUpdateScroll;
