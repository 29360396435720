import { useState, useCallback, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { focusManager } from '@accedo/vdkweb-navigation';
import { pageRedux } from '@accedo/vdkweb-tv-ui';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import Button from '#/components/Button/Button';
import type { INavMap } from '#/utils/navigationHelper';
import { getVerticalNav } from '#/utils/navigationHelper';
import { MAIN_MENU } from '#/utils/navigationMap';
import { useAppDispatch, useAppSelector, useCollapseSidebar } from '#/hooks';
import { getIsLowEndDevice } from '#/redux/modules/system';
import redux from '#/redux/modules';
import NavArrow from '#/static/images/icons/nav.svg';
import Logo from '#/components/Logo/Logo';
import { composeSidebarLabel } from '#/utils/accessibility';
import useDirectPublisherConfig from '#/services/directPublisher/directPublisher';

import styles from './sidebar.scss';

const {
  menu: { getIsSidebarActive, getIsSidebarCollapsed },
} = redux;

let nav: INavMap<string, any> | null = null;

const { MENU_CONTAINER, MENU, ITEM } = MAIN_MENU;

const navMap = {
  MENU_CONTAINER: {
    id: MENU_CONTAINER,
  },
  MENU: {
    id: MENU,
    parent: MENU_CONTAINER,
    forwardFocus: `${ITEM}_0`,
  },
};

const buildMenuNav = (menuItems: any[]) => {
  nav = getVerticalNav(
    menuItems.map((_, i) => `${ITEM}_${i}`),
    {
      parent: MENU,
    },
    true,
  );
};

const Sidebar = () => {
  const history = useHistory();
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);
  const isSidebarActive = useAppSelector(getIsSidebarActive);
  const isSidebarCollapsed = useAppSelector(getIsSidebarCollapsed);
  const [menuItems, setMenuItems] = useState<any>();
  const collapseSidebar = useCollapseSidebar();
  const dispatch = useAppDispatch();
  const directPublisherConfig = useDirectPublisherConfig();

  const getMenuItems = useCallback(async () => {
    const items = await directPublisherConfig.fetchMenuItems();

    setMenuItems(items);
    buildMenuNav(items);
  }, []);

  const openMenu = useCallback(() => {
    collapseSidebar(false);
    focusManager.changeFocus(MENU);
  }, []);

  const closeMenu = useCallback(() => {
    collapseSidebar(true);
    dispatch(pageRedux.actions.pageFocusCurrent());
  }, []);

  const to = useCallback(
    path => () => {
      closeMenu();
      history.push(`${path}`);
    },
    [],
  );

  useEffect(() => {
    getMenuItems();
  }, []);

  useEffect(() => {
    return history.listen(() => {
      collapseSidebar(true);
    });
  }, [history]);

  return (
    <FocusDiv
      className={classNames(styles.container, {
        [styles.collapsed]: isSidebarCollapsed,
        [styles.hidden]: !isSidebarActive,
        [styles.animation]: !isLowEndDevice,
      })}
      onFocus={openMenu}
      nav={navMap.MENU_CONTAINER}
    >
      <Logo className={styles.logo} />

      {isSidebarCollapsed && (
        <div className={styles.collapsedIcon}>
          <img src={NavArrow} alt="Collapsed" />
        </div>
      )}

      <FocusDiv
        nav={{
          ...navMap.MENU,
          internal: {
            nextright: closeMenu,
          },
        }}
      >
        {menuItems &&
          !isSidebarCollapsed &&
          menuItems.map((item: any, i: number) => (
            <Button
              key={item._meta.id}
              nav={nav?.[`${ITEM}_${i}`]}
              onClick={to(item?.route)}
              className={styles.menuItem}
              classNameFocused={styles.menuItemFocused}
              accessibilityLabel={composeSidebarLabel(item?.displayText)}
            >
              {item?.displayText}
            </Button>
          ))}
      </FocusDiv>
    </FocusDiv>
  );
};

export default memo(Sidebar);
