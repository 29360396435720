import classNames from 'classnames';

import styles from './input.scss';

type Props = {
  value?: string;
  type?: string;
  className?: string;
  classNameFocused?: string;
  placeholder?: string;
  readOnly?: boolean;
  domRef?: React.Ref<HTMLInputElement>;
};

const Input = ({
  value,
  type = 'text',
  className,
  placeholder = '',
  readOnly = false,
  domRef = null,
}: Props) => {
  return (
    <input
      ref={domRef}
      value={value}
      type={type}
      readOnly={readOnly}
      className={classNames(styles.input, className || styles.defaultStyle)}
      placeholder={placeholder}
    />
  );
};

export default Input;
