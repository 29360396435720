import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type MenuState = {
  isSidebarActive: boolean;
  isSidebarCollapsed: boolean;
};

const name = 'menu';

const initialState: MenuState = {
  isSidebarActive: true,
  isSidebarCollapsed: true,
};

export const {
  reducer,
  actions: { setSidebarActive, setSidebarCollapsed },
} = createSlice({
  name,
  initialState,
  reducers: {
    setSidebarActive(
      state = initialState,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isSidebarActive = payload;
    },
    setSidebarCollapsed(
      state = initialState,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isSidebarCollapsed = payload;
    },
  },
});

let rootSelector: RootSelector<MenuState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<MenuState> = selector => {
  rootSelector = selector;
};

export const getIsSidebarActive = (state: RootState) =>
  rootSelector(state).isSidebarActive;
export const getIsSidebarCollapsed = (state: RootState) =>
  rootSelector(state).isSidebarCollapsed;
