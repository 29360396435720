import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from '@accedo/vdkweb-tv-ui';
import { environment as sEnv } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';

import { PLAYER_PAGE } from '#/utils/navigationMap';
import { useToggleMenu, useVideoPlayer, usePreventDefaultBack } from '#/hooks';
import VideoPlayer from '#/components/VideoPlayer/VideoPlayer';
import PlayerControls from '#/components/PlayerControls/PlayerControls';
import getResolution from '#/utils/getResolution';
import { historyService } from '#/services/historyService/historyService';
import { useNativeFocus } from '#/utils/accessibility';

import styles from './player.scss';

const { PAGE, CONTROLS, PLAY_PAUSE_BTN } = PLAYER_PAGE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTROLS,
  },
  CONTROLS: {
    id: CONTROLS,
    parent: PAGE,
    forwardFocus: PLAY_PAUSE_BTN,
  },
};

const size = {
  ...getResolution(),
  left: 0,
  top: 0,
};

const DashPlayerView = () => {
  const videoElement = useRef(null);
  const toggleMenu = useToggleMenu();
  const history = useHistory();
  const preventDefaultBack = usePreventDefaultBack();
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    play,
    pause,
  } = useVideoPlayer(videoElement);

  const onBack = ({ id }: { id: string }) => {
    const { BACK } = vKey;

    const historyStack = historyService.getStack();

    switch (id) {
      case BACK.id:
        if (historyStack?.length === 1) {
          history.replace('/');
        } else {
          history.goBack();
        }

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    toggleMenu(false);

    return () => {
      toggleMenu(true);
    };
  }, []);

  useEffect(() => {
    useNativeFocus();
    preventDefaultBack(true);

    sEnv.addEventListener(sEnv.SYSTEM.KEYDOWN, onBack);

    return () => {
      sEnv.removeEventListener(sEnv.SYSTEM.KEYDOWN, onBack);
    };
  }, []);

  return (
    <Page nav={navMap.PAGE} className={styles.wrapper}>
      <VideoPlayer
        onTimeUpdate={handleOnTimeUpdate}
        domRef={videoElement}
        width={size.width}
        height={size.height}
      />

      <PlayerControls
        nav={navMap.CONTROLS}
        videoRef={videoElement}
        onTogglePlay={togglePlay}
        onSeek={handleVideoProgress}
        playerState={playerState}
        play={play}
        pause={pause}
      />
    </Page>
  );
};

export default DashPlayerView;
