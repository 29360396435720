export const secondsToHrs = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);

  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);

  if (hours < 1) {
    return `${minutes}m`;
  }

  if (minutes > 0) {
    return `${hours}h ${minutes}m`;
  }

  return `${hours}h`;
};

export const getProgressPercentange = (
  duration: number,
  currentTime: number,
) => {
  return `${((currentTime || 0) / duration) * 100}%`;
};

export const getRandomProgress = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min);
};
