import { useState, useEffect } from 'react';

const useVideoPlayer = (videoElement: any) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: true,
    progress: 0,
    duration: 573.6,
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  const play = () => {
    setPlayerState({
      ...playerState,
      isPlaying: true,
    });
  };

  const pause = () => {
    setPlayerState({
      ...playerState,
      isPlaying: false,
    });
  };

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    setPlayerState({
      ...playerState,
      progress: videoElement.current.currentTime,
    });
  };

  const handleVideoProgress = (delta: number) => {
    videoElement.current.currentTime += delta;
    setPlayerState({
      ...playerState,
      progress: (videoElement.current.currentTime += delta),
    });
  };

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    play,
    pause,
  };
};

export default useVideoPlayer;
