import { useCallback, useRef } from 'react';

function useInterval(callback: () => void, delay: number) {
  const intervalRef = useRef(-1);

  const clear = useCallback(() => {
    if (intervalRef.current > -1) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = -1;
    }
  }, []);

  const start = useCallback(() => {
    clear();

    intervalRef.current = window.setInterval(() => {
      callback();
    }, delay);
  }, [callback]);

  return [start, clear];
}

export default useInterval;
