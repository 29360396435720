import { useState, useMemo, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { focusManager } from '@accedo/vdkweb-navigation';

import { getIsLowEndDevice } from '#/redux/modules/system';
import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { useUpdateEffect, useLatest, useAppSelector } from '#/hooks';

import VisualWindow from './VisualWindow';
import { composeItemId, getItemsProps } from './swimlaneUtils';
import styles from './swimlane.scss';

const MIN_SCROLL = 0;

type Props<ItemDataType, ItemExtraData> = {
  nav: XDKNav;
  data: (ItemDataType | OverflowType)[];
  itemExtraData?: ItemExtraData;
  hideOverflow?: boolean;
  resetAxisOnItemsChanged?: boolean;
  className?: string;
  itemType: string;
  displayText?: string;
  playlistUrl?: string;
  withViewMore?: boolean;
  removeItemsOffScreen?: boolean;
  containerItemLimiter?: number;
  component: React.ComponentType<any>;
  onFocus?: (id: string) => void;
  onSwimlaneReady?: () => void;
  getItemData?: (
    item: ItemDataType | OverflowType,
  ) => ItemDataType | OverflowType;
};

const getFixedFocusScroll = (currentItem: HTMLElement): number => {
  return currentItem?.offsetLeft * -1;
};

const Swimlane = <ItemDataType, ItemExtraData>({
  nav,
  data = [],
  itemExtraData,
  hideOverflow = false,
  resetAxisOnItemsChanged = false,
  className = '',
  itemType = '',
  displayText = '',
  playlistUrl = '',
  withViewMore = false,
  removeItemsOffScreen = true,
  containerItemLimiter = 0,
  component,
  onFocus,
  onSwimlaneReady,
  getItemData,
}: Props<ItemDataType, ItemExtraData>) => {
  const [axis, setAxis] = useState(0);
  const latestAxisRef = useLatest(axis);
  const latestOnFocusRef = useLatest(onFocus);
  const shouldResetAxisRef = useLatest(resetAxisOnItemsChanged);
  const currentIndexRef = useRef(0);
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  const onItemFocus = useCallback(index => {
    const currentItem = document.getElementById(composeItemId(nav.id, index));
    const currentAxis = latestAxisRef.current;

    if (!currentItem) {
      return;
    }

    if (latestOnFocusRef.current) {
      latestOnFocusRef.current(composeItemId(nav.id, index));
    }

    currentIndexRef.current = index;

    let newAxis = currentAxis;

    newAxis = getFixedFocusScroll(currentItem);

    setAxis(newAxis);
  }, []);

  const onWrapperFocus = useCallback(() => {
    focusManager.changeFocus(composeItemId(nav.id, currentIndexRef.current));
  }, [nav.id]);

  useUpdateEffect(() => {
    if (shouldResetAxisRef.current && latestAxisRef.current !== 0) {
      currentIndexRef.current = 0;
      setAxis(MIN_SCROLL);
    }
  }, [data]);

  const items = useMemo(() => {
    let parsedData = data;

    if (containerItemLimiter > 0 && data.length > containerItemLimiter) {
      parsedData = withViewMore
        ? [
            ...data.slice(0, containerItemLimiter),
            { isViewMore: withViewMore, playlistUrl },
          ]
        : [...data.slice(0, containerItemLimiter)];
    }

    return getItemsProps<ItemDataType, ItemExtraData>({
      data: parsedData,
      getItemData,
      component,
      title: displayText,
      itemType,
      onItemFocus,
      itemExtraData,
      swimlaneId: nav.id,
    });
  }, [data]);

  return (
    <FocusDiv
      nav={nav}
      onFocus={onWrapperFocus}
      className={classNames(styles.wrapper, className, {
        [styles.noOverflow]: hideOverflow,
      })}
    >
      <div className={styles.header}>{displayText}</div>

      <div
        className={classNames('swimlane', itemType, {
          [styles.innerWrapper]: !isLowEndDevice,
        })}
        style={{
          transform: `translateX(${axis}px)`,
        }}
      >
        {items.length && (
          <VisualWindow
            swimlaneId={nav.id}
            items={items}
            removeItemsOffScreen={removeItemsOffScreen}
            axis={axis}
            onSwimlaneReady={onSwimlaneReady}
          />
        )}
      </div>
    </FocusDiv>
  );
};

export default Swimlane;
