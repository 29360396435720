import Backspace from '#/static/images/icons/keyboard/backspace.svg';

import { Key, createHorizontalKeyNav } from '../Keyboard/keyboardUtils';

const ROW1: Key[] = [
  {
    text: '1',
    value: '1',
    special: false,
    accessibilityLabel: '1',
    nav: {
      id: '1_PIN',
      nextdown: '4_PIN',
    },
    row: 1,
  },
  {
    text: '2',
    value: '2',
    special: false,
    accessibilityLabel: '2',
    nav: {
      id: '2_PIN',
      nextdown: '5_PIN',
    },
    row: 1,
  },
  {
    text: '3',
    value: '3',
    special: false,
    accessibilityLabel: '3',
    nav: {
      id: '3_PIN',
      nextdown: '6_PIN',
    },
    row: 1,
  },
];

const ROW2: Key[] = [
  {
    text: '4',
    value: '4',
    special: false,
    accessibilityLabel: '4',
    nav: {
      id: '4_PIN',
      nextdown: '7_PIN',
      nextup: '1_PIN',
    },
    row: 2,
  },
  {
    text: '5',
    value: '5',
    special: false,
    accessibilityLabel: '5',
    nav: {
      id: '5_PIN',
      nextdown: '8_PIN',
      nextup: '2_PIN',
    },
    row: 2,
  },
  {
    text: '6',
    value: '6',
    special: false,
    accessibilityLabel: '6',
    nav: {
      id: '6_PIN',
      nextdown: '9_PIN',
      nextup: '3_PIN',
    },
    row: 2,
  },
];

const ROW3: Key[] = [
  {
    text: '7',
    value: '7',
    special: false,
    accessibilityLabel: '7',
    nav: {
      id: '7_PIN',
      nextdown: '0_PIN',
      nextup: '4_PIN',
    },
    row: 3,
  },
  {
    text: '8',
    value: '8',
    special: false,
    accessibilityLabel: '8',
    nav: {
      id: '8_PIN',
      nextdown: '0_PIN',
      nextup: '5_PIN',
    },
    row: 3,
  },
  {
    text: '9',
    value: '9',
    special: false,
    accessibilityLabel: '9',
    nav: {
      id: '9_PIN',
      nextdown: 'DELETE_PIN',
      nextup: '6_PIN',
    },
    row: 3,
  },
];

const ROW4: Key[] = [
  {
    text: '',
    value: '',
    altText: '',
    altValue: '',
    special: true,
    accessibilityLabel: '',
    nav: {
      id: 'clr',
    },
    row: 4,
  },
  {
    text: '0',
    value: '0',
    special: false,
    accessibilityLabel: '0',
    nav: {
      id: '0_PIN',
      nextup: '8_PIN',
      nextleft: '',
      nextright: 'DELETE_PIN',
    },
    row: 4,
  },
  {
    text: 'Delete',
    value: 'DELETE_PIN',
    accessibilityLabel: 'Delete',
    icon: {
      src: Backspace,
    },
    special: false,
    nav: {
      id: 'DELETE_PIN',
      nextup: '9_PIN',
      nextleft: '0_PIN',
      nextright: '',
    },
    row: 4,
  },
];

export const KEYS: Key[][] = [
  [...createHorizontalKeyNav(ROW1)],
  [...createHorizontalKeyNav(ROW2)],
  [...createHorizontalKeyNav(ROW3)],
  [...ROW4],
];
