import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import Slider from '@material-ui/core/Slider';
import { environment } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import { withFocus } from '@accedo/vdkweb-navigation';

import { usePrevious, useLatest } from '#/hooks';

import styles from './seekBar.scss';
import formatTime from './formatTime';

const { LEFT, RIGHT } = vKey;

type Props = {
  onSeek: any;
  playerState: any;
  seekDelta: number;
};

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;

const SeekBar = ({
  nav: { id: navId },
  playerState,
  onSeek,
  seekDelta,
  isFocused,
}: Props & WithFocusProps) => {
  const [percentage, setPercentage] = useState(0);
  const currentPercentageRef = useLatest(percentage);
  const lastPreviousPercentageRef = useLatest(usePrevious(percentage));
  const isFocusedRef = useLatest(isFocused);

  const onChange = useCallback((_, value) => {
    const percentageValue = value;

    setPercentage(percentageValue);
  }, []);

  const onChangeCommitted = useCallback(
    (_event, value) => {
      const newPercentage = value;
      const prevPercentage = lastPreviousPercentageRef.current ?? 0;
      const currentPercentage = currentPercentageRef.current ?? 0;
      const percentageDiff =
        newPercentage === prevPercentage
          ? newPercentage - currentPercentage
          : newPercentage - prevPercentage;

      setPercentage(newPercentage);
      onSeek((percentageDiff * playerState.duration) / 100);
    },
    [playerState.duration],
  );

  const onKeydown = useCallback(({ id }) => {
    if (!isFocusedRef.current) {
      return;
    }

    switch (id) {
      case LEFT.id:
        onSeek(-seekDelta);
        break;
      case RIGHT.id:
        onSeek(seekDelta);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const newPercentage = playerState.duration
      ? (playerState.progress * 100) / playerState.duration
      : 0;

    setPercentage(
      Math.min(Math.max(newPercentage, MIN_PERCENTAGE), MAX_PERCENTAGE),
    );
  }, [playerState.progress, playerState.duration]);

  useEffect(() => {
    environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeydown);

    return () => {
      environment.removeEventListener(environment.SYSTEM.KEYDOWN, onKeydown);
    };
  }, []);

  return (
    <div className={styles.container} id={navId} tabIndex={-1}>
      <span className={classNames(styles.time, styles.left)}>
        <span className={styles.label}>{formatTime(playerState.progress)}</span>
      </span>

      <div className={styles.sliderContainer}>
        <Slider
          onChange={onChange}
          classes={{
            root: styles.slider,
            colorPrimary: styles.colorPrimary,
            thumb: classNames(styles.thumb, {
              [styles.thumbFocused]: isFocused,
            }),
            track: styles.track,
            rail: styles.rail,
          }}
          onChangeCommitted={onChangeCommitted}
          value={percentage}
        />
      </div>

      <span className={classNames(styles.time, styles.right)}>
        <span className={styles.label}>
          {formatTime(playerState.duration - playerState.progress)}
        </span>
      </span>
    </div>
  );
};

export default withFocus(SeekBar);
