import { memo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { getIsLowEndDevice } from '#/redux/modules/system';
import { useUpdateEffect, useAppSelector, useImage } from '#/hooks';
import Placeholder from '#/static/images/placeholder/placeholder_width.png';

import styles from './tiles.scss';

const EpisodeTile = ({
  isFocused,
  clicked,
  data,
}: SwimlaneItemProps<TypeTile>) => {
  const history = useHistory();
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);
  const bgImage = data?.poster?.formats?.small?.url || Placeholder;
  const imageLoaded = useImage(bgImage);

  useUpdateEffect(() => {
    history.push('/player');
  }, [clicked]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.baseTileContainer, {
          [styles.focused]: isFocused,
          [styles.loaded]: imageLoaded,
          [styles.animation]: !isLowEndDevice,
        })}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className={styles.bottomGradient} />

        {/* Use proper episode duration here */}
        <div className={styles.timeString}>45 min</div>
      </div>
      <div className={styles.baseTileTitle}>{data?.name}</div>
    </div>
  );
};

export default memo(EpisodeTile);
