import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';

import { SEARCH_PAGE } from '#/utils/navigationMap';
import Input from '#/components/Input/Input';
import Keyboard from '#/components/Keyboard/Keyboard';
import Grid from '#/components/Grid/Grid';
import { PortraitTile, PORTRAIT_TILE_GRID } from '#/components/Tiles';
import { useToSidebarNav, useAppSelector } from '#/hooks';
import Spinner from '#/components/Spinner/Spinner';
import { getMovies } from '#/redux/modules/movies';
import { getSeries } from '#/redux/modules/series';

import styles from './search.scss';

const { PAGE, KEYBOARD, ACTIVE_COMPONENT } = SEARCH_PAGE;

const TIME_TO_START_SEARCH = 2000;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: KEYBOARD,
    useLastFocus: true,
  },
  KEYBOARD: {
    id: KEYBOARD,
    parent: PAGE,
  },
  ACTIVE_COMPONENT: {
    id: ACTIVE_COMPONENT,
    parent: PAGE,
    nextup: KEYBOARD,
  },
};

type Params = {
  searchTerm: string;
};

const insertChar = (str: string, char: string, position: number): string => {
  return (
    str.substring(0, position) + char + str.substring(position, str.length)
  );
};

const Search = () => {
  const [searching, setSearching] = useState<boolean>(false);
  // Refactor
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchTerm } = useParams<Params>();
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [results, setResults] = useState<any>([]);
  const moviesData = useAppSelector(getMovies);
  const seriesData = useAppSelector(getSeries);
  const [noResults, setNotResults] = useState(false);
  const totalFeedRef = useRef([...moviesData, ...seriesData]);

  const startSearch = async (searchTermParam: string): Promise<any> => {
    focusManager.changeFocus(KEYBOARD);
    setNotResults(false);
    setSearching(true);

    const searchTermUrl = searchTermParam.trim().replace(/\s/g, '+');

    const searchResults = totalFeedRef.current.filter(o =>
      o.name.toLowerCase().includes(searchTermUrl),
    );

    if (searchResults.length > 0) {
      setResults(searchResults);
    } else {
      focusManager.changeFocus(KEYBOARD);
      setNotResults(true);
      setResults([]);
    }
  };

  const isReadyToStartSearch = (lastSearch: string): void => {
    if (lastSearch.length >= 2 && lastSearch === inputRef.current?.value) {
      startSearch(lastSearch);
    }
  };

  const updateCursorPosition = (direction: number) => {
    setCursorPosition(cursorPosition + direction);
    inputRef.current?.setSelectionRange(
      cursorPosition + direction,
      cursorPosition + direction,
    );
    inputRef.current?.focus();
  };

  const keyInput = (keyValue: string) => {
    if (!inputRef.current) {
      return;
    }

    const lastInput = inputRef.current.value;

    switch (keyValue) {
      case 'LEFT':
        if (cursorPosition === 0) {
          return;
        }

        updateCursorPosition(-1);
        break;
      case 'RIGHT':
        if (cursorPosition === lastInput.length) {
          return;
        }

        updateCursorPosition(1);
        break;
      case 'SPACE':
        inputRef.current.value = insertChar(lastInput, ' ', cursorPosition);
        updateCursorPosition(1);
        break;
      case 'DELETE':
        if (lastInput.length === 0 || cursorPosition === 0) {
          return;
        }

        inputRef.current.value =
          lastInput.slice(0, cursorPosition - 1) +
          lastInput.slice(cursorPosition);

        updateCursorPosition(-1);

        if (inputRef.current.value.length === 0) {
          inputRef.current.blur();
        }

        break;
      default:
        inputRef.current.value = insertChar(
          lastInput,
          keyValue,
          cursorPosition,
        );
        updateCursorPosition(1);
    }

    setTimeout(
      (lastSearch: string) => {
        isReadyToStartSearch(lastSearch);
      },
      TIME_TO_START_SEARCH,
      inputRef.current.value,
    );
  };

  if (!seriesData && !moviesData) {
    return <Spinner force />;
  }

  return (
    <Page nav={navMap.PAGE} className={styles.wrapper}>
      <Input
        domRef={inputRef}
        className={styles.searchInput}
        value={searchTerm}
        placeholder="What do you want to watch?"
      />

      <Keyboard
        nav={{
          ...navMap.KEYBOARD,
          nextdown: searching && results.length > 0 ? ACTIVE_COMPONENT : '',
        }}
        onClick={keyInput}
      />

      {!searching && (
        <div className={styles.noSearchingText}>
          Search for movies and shows...
        </div>
      )}

      {searching && noResults && (
        <div className={styles.noSearchingText}>No results</div>
      )}

      {searching && (
        <div className={styles.gridContainer}>
          <Grid
            nav={useToSidebarNav(navMap.ACTIVE_COMPONENT)}
            data={results}
            component={PortraitTile}
            classNameItemWrapper={PORTRAIT_TILE_GRID}
            maxItemsRow={7}
          />
        </div>
      )}
    </Page>
  );
};

export default Search;
