import { useState } from 'react';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import Button from '#/components/Button/Button';
import KeyWithIcon from '#/components/Keyboard/KeyWithIcon';

import { Key } from './keyboardUtils';
import { KEYS } from './keyMap';
import styles from './keyboard.scss';

const UPPERCASE = 'UPPERCASE';
const LOWERCASE = 'LOWERCASE';
const NUMBERS = 'NUMBERS';
const SPECIAL_CHARS = 'SPECIAL_CHARS';
const OTHER_CHARS = 'OTHER_CHARS';

const FIRST_KEY = KEYS[0].nav.id;

type Props = {
  updateScroll?: () => void;
  onClick: (a: string) => void;
  nav: XDKNav;
};

const Keyboard = ({ nav, onClick, updateScroll }: Props) => {
  const [firstRowChars, setFirstRowChars] = useState<string>(LOWERCASE);

  let focusKeyboard;

  if (updateScroll) {
    focusKeyboard = () => {
      updateScroll();
    };
  }

  const onClickSpecialKeys = (value: string): void => {
    switch (value) {
      case UPPERCASE:
        firstRowChars === UPPERCASE
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(UPPERCASE);
        break;
      case NUMBERS:
        firstRowChars === NUMBERS
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(NUMBERS);
        break;
      case SPECIAL_CHARS:
        firstRowChars === SPECIAL_CHARS
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(SPECIAL_CHARS);
        break;
      case OTHER_CHARS:
        firstRowChars === OTHER_CHARS
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(OTHER_CHARS);
        break;
      default:
        onClick(value);
    }
  };

  return (
    <FocusDiv
      onFocus={focusKeyboard}
      nav={{ ...nav, forwardFocus: FIRST_KEY, useLastFocus: true }}
      className={styles.wrapper}
    >
      <div className={styles.row}>
        {KEYS.filter(item => {
          if (firstRowChars === NUMBERS) {
            return item.row === 3;
          }

          if (firstRowChars === SPECIAL_CHARS) {
            return item.row === 4;
          }

          if (firstRowChars === OTHER_CHARS) {
            return item.row === 5;
          }

          return item.row === 1;
        }).map(item => {
          const value =
            firstRowChars === UPPERCASE && item.altText
              ? item.altText
              : item.text;

          const customSize = `size${item.customSize
            ?.toString()
            .replace('.', 'x')}`;

          const keyNav = {
            ...item.nav,
            nextdown: item.nav.nextdown,
            parent: nav.id,
          };

          return (
            <Button
              key={item.text}
              nav={keyNav}
              className={`${styles.key} ${styles.keyColor} ${
                firstRowChars === NUMBERS
                  ? styles.keyNumberSize
                  : styles[customSize]
              }`}
              classNameFocused={styles.keyFocused}
              onClick={() => {
                onClick(value);
              }}
              accessibilityLabel={item?.accessibilityLabel}
            >
              {value}
            </Button>
          );
        })}
      </div>

      <div className={styles.row}>
        {KEYS.filter((item: Key) => item.row === 2).map((item: Key) => {
          const customSize = `size${item.customSize
            ?.toString()
            .replace('.', 'x')}`;

          let navUp: any = item.nav;

          if (firstRowChars === 'NUMBERS') {
            navUp = { ...item.nav, ...item.numbersNav };
          } else if (firstRowChars === SPECIAL_CHARS) {
            navUp = { ...item.nav, ...item.specialNav };
          } else if (firstRowChars === OTHER_CHARS) {
            navUp = { ...item.nav, ...item.otherCharsNav };
          }

          const keyNav = {
            ...navUp,
            parent: nav.id,
          };

          return item.icon ? (
            <KeyWithIcon
              key={item.text}
              nav={keyNav}
              item={item}
              className={`${styles.key} ${styles[customSize]}`}
              classNameFocused={styles.keyFocused}
              classNameIcon={styles.buttonIcon}
              onClick={onClickSpecialKeys}
              accessibilityLabel={item.accessibilityLabel}
            />
          ) : (
            <Button
              key={item.text}
              nav={keyNav}
              className={`${styles.key} ${styles.keyColor} ${styles[customSize]}`}
              classNameFocused={styles.keyFocused}
              onClick={() => {
                onClickSpecialKeys(item.value);
              }}
              accessibilityLabel={item.accessibilityLabel}
            >
              {item.text}
            </Button>
          );
        })}
      </div>
    </FocusDiv>
  );
};

export default Keyboard;
