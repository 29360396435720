import { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import { environment as sEnv } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';

import { DETAILS_PAGE } from '#/utils/navigationMap';
import {
  useToggleMenu,
  useUpdateScroll,
  useImage,
  useAppSelector,
  usePreventDefaultBack,
} from '#/hooks';
import Spinner from '#/components/Spinner/Spinner';
import Scroll from '#/components/Scroll/Scroll';
import FocusDiv from '#/components/FocusDiv/FocusDiv';
import LeftGradient from '#/static/images/gradients/dark-gradient-left.png';
import VerticalGradient from '#/static/images/gradients/dark-gradient-vertical.png';
import Button from '#/components/Button/Button';
import SeasonPicker from '#/components/SeasonPicker/SeasonPicker';
import { historyService } from '#/services/historyService/historyService';
import Placeholder from '#/static/images/placeholder/placeholder_height.png';
import { composeDetailsLabel, useNativeFocus } from '#/utils/accessibility';

import styles from './details.scss';
import { getMovies } from '#/redux/modules/movies';
import { getSeries } from '#/redux/modules/series';

const { PAGE, HEADER, PLAY_BTN, SEASONS } = DETAILS_PAGE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: HEADER,
    useLastFocus: true,
  },
  HEADER: {
    id: HEADER,
    parent: PAGE,
    forwardFocus: PLAY_BTN,
  },
  PLAY_BTN: {
    id: PLAY_BTN,
    parent: HEADER,
    nextdown: '',
  },
  SEASONS: {
    id: SEASONS,
    parent: PAGE,
    nextup: HEADER,
  },
};

type Params = {
  type: string;
  assetId: string;
};

const Details = () => {
  const { type, assetId } = useParams<Params>();
  const [pageData, setPageData] = useState<any>();
  const updateScroll = useUpdateScroll(PAGE);
  const bgImage = pageData?.asset?.backdrop?.formats?.large?.url;
  const posterImage =
    pageData?.asset?.poster?.formats?.large?.url || Placeholder;
  const bgImageLoaded = useImage(bgImage);
  const posterImageLoaded = useImage(posterImage);
  const preventDefaultBack = usePreventDefaultBack();
  const history = useHistory();
  const toggleMenu = useToggleMenu();
  const moviesData = useAppSelector(getMovies);
  const seriesData = useAppSelector(getSeries);

  const onBack = ({ id }: { id: string }) => {
    const { BACK } = vKey;

    const historyStack = historyService.getStack();

    switch (id) {
      case BACK.id:
        if (historyStack?.length <= 2) {
          history.replace('/');
        } else {
          history.goBack();
        }

        break;
      default:
        break;
    }
  };

  const findAsset = (data: any, id: number) => {
    return data.find((asset: any) => asset.id === id);
  };

  const getPageData = useCallback(async () => {
    let asset: any = findAsset(seriesData, parseInt(assetId, 10));

    if (type === 'movie') {
      asset = findAsset(moviesData, parseInt(assetId, 10));

      setPageData({
        asset,
      });

      return;
    }

    setPageData({
      asset,
    });
  }, [assetId, type]);

  const focusHeader = () => {
    updateScroll(HEADER);
  };

  useEffect(() => {
    preventDefaultBack(true);
    toggleMenu(false);

    return () => {
      toggleMenu(true);
    };
  }, []);

  useEffect(() => {
    getPageData();
  }, [assetId, type]);

  useEffect(() => {
    if (pageData) {
      focusManager.changeFocus(HEADER);
      useNativeFocus();
    }
  }, [pageData]);

  useEffect(() => {
    sEnv.addEventListener(sEnv.SYSTEM.KEYDOWN, onBack);

    return () => {
      sEnv.removeEventListener(sEnv.SYSTEM.KEYDOWN, onBack);
    };
  }, []);

  if (!pageData) {
    return <Spinner force />;
  }

  return (
    <Page nav={navMap.PAGE} className={styles.wrapper}>
      <Scroll id={PAGE} extraPush={250}>
        <FocusDiv nav={navMap.HEADER} className={styles.header}>
          <div className={styles.title}>{pageData?.asset?.name}</div>

          <div className={styles.metadata}>
            {type === 'movie' && (
              <>
                <span>
                  {new Date(
                    pageData?.asset?.metadata?.releaseDate,
                  ).getFullYear() || 'Unavailable'}
                </span>

                <span className={styles.dot}>•</span>
              </>
            )}

            <span>
              {(pageData?.asset?.movieGenres || pageData?.asset?.tvShowGenres)
                .map((i: any) => i.name)
                .join(' / ')}
            </span>
          </div>

          <Button
            nav={{
              ...navMap.PLAY_BTN,
              nextdown: type !== 'movie' ? SEASONS : '',
            }}
            className={styles.playBtn}
            onFocus={focusHeader}
            onClick={() => history.push('/player')}
            accessibilityLabel={composeDetailsLabel(
              pageData?.asset?.name,
              type === 'movie',
            )}
          >
            Play
          </Button>

          <div className={styles.description}>
            {pageData?.asset?.description}
          </div>

          <div className={styles.crewContainer}>
            <div className={styles.crew}>
              <div className={styles.crewTitle}>Director:</div>
              {pageData?.asset?.metadata?.director || 'Unavailable'}
            </div>
            <div className={styles.crew}>
              <div className={styles.crewTitle}>Cast:</div>
              Unavailable
            </div>
          </div>

          <div
            className={classNames(styles.bgImage, {
              [styles.loaded]: bgImageLoaded,
            })}
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
          />

          <div
            className={classNames(styles.poster, {
              [styles.loaded]: posterImageLoaded,
            })}
            style={{
              backgroundImage: `url(${posterImage})`,
            }}
          />

          <img
            src={LeftGradient}
            alt="Horizontal Gradient"
            className={styles.gradient}
          />

          <img
            src={VerticalGradient}
            alt="Vertical Gradient"
            className={styles.gradient}
          />
        </FocusDiv>

        {type === 'show' && (
          <SeasonPicker
            nav={navMap.SEASONS}
            onFocus={updateScroll}
            seasons={pageData?.asset?.tvSeasonsResolved
              .filter((season: any) => season?.number !== 0)
              .sort((a: any, b: any) => a.number - b.number)}
          />
        )}
      </Scroll>
    </Page>
  );
};

export default Details;
