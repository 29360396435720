import { useEffect, useCallback } from 'react';

import {
  fetchConfig,
  getIsFetching,
  getConfig,
  getContentFeed,
} from '#/redux/modules/config';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

function useConfig() {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(getIsFetching);
  const config = useAppSelector(getConfig);
  const contentFeed = useAppSelector(getContentFeed);
  const loaded = !!(!isFetching && config);

  const loadConfig = useCallback(() => {
    if (!isFetching) {
      dispatch(fetchConfig());
    }
  }, [isFetching]);

  useEffect(() => {
    loadConfig();
  }, []);

  return [loaded, config, contentFeed] as const;
}

export default useConfig;
