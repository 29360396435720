import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '@accedo/vdkweb-tv-ui';

import { VIEW_MORE_PAGE } from '#/utils/navigationMap';
import { useToggleMenu } from '#/hooks';
import useOrchestra from '#/services/orchestra/api';
import Spinner from '#/components/Spinner/Spinner';
import Grid from '#/components/Grid/Grid';
import { useNativeFocus } from '#/utils/accessibility';
import {
  LandscapeTile,
  PortraitTile,
  LANDSCAPE,
  PORTRAIT_TILE_GRID,
  LANDSCAPE_TILE_GRID,
} from '#/components/Tiles';

import styles from './viewMore.scss';

const { PAGE, GRID } = VIEW_MORE_PAGE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: GRID,
    useLastFocus: true,
  },
  GRID: {
    id: GRID,
    parent: PAGE,
  },
};

type Params = {
  title: string;
  type: string;
  asset: string;
  playlistId: string;
};

const ViewMore = () => {
  const { title, type, asset, playlistId } = useParams<Params>();
  const [pageData, setPageData] = useState<any>();
  const toggleMenu = useToggleMenu();
  const orchestra = useOrchestra();

  const getPageData = useCallback(async () => {
    const playlist: any = await orchestra.fetchPlayslistById(playlistId, asset);

    setPageData({ playlist });
  }, []);

  useEffect(() => {
    toggleMenu(false);

    return () => {
      toggleMenu(true);
    };
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    if (pageData) {
      useNativeFocus();
    }
  }, [pageData]);

  if (!pageData) {
    return <Spinner force />;
  }

  return (
    <Page nav={navMap.PAGE} className={styles.wrapper}>
      <div className={styles.header}>{title}</div>

      <Grid
        nav={navMap.GRID}
        data={pageData?.playlist?.movies || pageData?.playlist?.tvShows}
        component={type === LANDSCAPE ? LandscapeTile : PortraitTile}
        classNameItemWrapper={
          type === LANDSCAPE ? LANDSCAPE_TILE_GRID : PORTRAIT_TILE_GRID
        }
        maxItemsRow={type === LANDSCAPE ? 5 : 7}
      />
    </Page>
  );
};

export default ViewMore;
