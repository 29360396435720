import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type ParentalControlState = {
  parentalControlData: ParentalControlItem[];
  parentalControlLevel: string | null;
};

export type ParentalControlItem = {
  displayName: string;
  id: number;
  ratings: Rating[];
};

export type Rating = {
  rating: string;
};

const name = 'parentalControl';

const initialState: ParentalControlState = {
  parentalControlData: [],
  parentalControlLevel: '2',
};

export const {
  reducer,
  actions: { setParentalControlData, setParentalControlLevel },
} = createSlice({
  name,
  initialState,
  reducers: {
    setParentalControlData(
      state,
      { payload }: PayloadAction<ParentalControlItem[]>,
    ) {
      state.parentalControlData = payload;
    },
    setParentalControlLevel(state, { payload }: PayloadAction<string>) {
      state.parentalControlLevel = payload;
    },
  },
});

let rootSelector: RootSelector<any> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<any> = selector => {
  rootSelector = selector;
};

export const getParentalControlData = (
  state: RootState,
): ParentalControlItem[] => rootSelector(state).parentalControlData;
export const getParentalControlLevel = (state: RootState): string =>
  rootSelector(state).parentalControlLevel;
