import { useRef, useCallback } from 'react';

function useTimeout(callback: () => void, delay = 0) {
  const timeoutRef = useRef(-1);

  const clear = useCallback(() => {
    if (timeoutRef.current > -1) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = -1;
    }
  }, []);

  const start = useCallback(() => {
    clear();

    timeoutRef.current = window.setTimeout(() => {
      callback();
      clear();
    }, delay);
  }, [callback]);

  return [start, clear];
}

export default useTimeout;
