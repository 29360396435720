import { memo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { getIsLowEndDevice } from '#/redux/modules/system';
import { useImage, useUpdateEffect, useAppSelector } from '#/hooks';
import Placeholder from '#/static/images/placeholder/placeholder_width.png';

import styles from './tiles.scss';

const LandscapeTile = ({
  isFocused,
  clicked,
  data,
}: SwimlaneItemProps<TypeTile>) => {
  const history = useHistory();
  const bgImage = data?.backdrop?.formats?.small?.url || Placeholder;
  const imageLoaded = useImage(bgImage);
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  useUpdateEffect(() => {
    if (data?.isLive) {
      history.push('/player');

      return;
    }

    if (data?.isMovie) {
      history.push(`/details/movie/${data?.id}`);
    } else if (data?.isShow) {
      history.push(`/details/show/${data?.id}`);
    }
  }, [clicked]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.baseTileContainer, {
          [styles.focused]: isFocused,
          [styles.loaded]: imageLoaded,
          [styles.animation]: !isLowEndDevice,
        })}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
        aria-hidden="true"
      >
        {data?.isLive && (
          <>
            <div className={styles.bottomGradient} />
            <div className={styles.liveTag}>L I V E</div>
          </>
        )}
      </div>
      <div className={styles.baseTileTitle} aria-label={data?.name}>
        {isFocused && data?.name}
      </div>
    </div>
  );
};

export default memo(LandscapeTile);
