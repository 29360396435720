import { useEffect, useCallback, useState } from 'react';

import { Page } from '@accedo/vdkweb-tv-ui';

import { HOME_PAGE } from '#/utils/navigationMap';
import Spinner from '#/components/Spinner/Spinner';
import { getVerticalNav } from '#/utils/navigationHelper';
import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { getContainerComponent } from '#/utils/containerUtils';
import { useUpdateScroll, useAppSelector } from '#/hooks';
import Scroll from '#/components/Scroll/Scroll';
import { useNativeFocus } from '#/utils/accessibility';
import useDirectPublisherConfig from '#/services/directPublisher/directPublisher';

import styles from './home.scss';
import { getMovies } from '#/redux/modules/movies';
import { getSeries } from '#/redux/modules/series';

const { PAGE, HOME_CONTAINERS } = HOME_PAGE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: HOME_CONTAINERS,
    useLastFocus: true,
  },
  HOME_CONTAINERS: {
    id: HOME_CONTAINERS,
    parent: PAGE,
    forwardFocus: '',
  },
};

export const populateContainers = (
  containers: Container[],
  contentFeed: any,
) => {
  const containerDataPromises = containers.map(container => {
    let data;

    if (container?.itemsResolved && container?.itemsResolved.length > 0) {
      data = container?.itemsResolved;
    }

    if (container?.playlistResolved?.movieIds) {
      data = container?.playlistResolved?.movieIds.map((id: number) =>
        contentFeed?.movies.find((el: any) => el.id === id),
      );
    }

    if (container?.playlistResolved?.tvShowIds) {
      data = container?.playlistResolved?.tvShowIds.map((id: number) =>
        contentFeed?.tvShows.find((el: any) => el.id === id),
      );
    }

    return {
      ...container,
      data,
    };
  });

  return containerDataPromises;
};

const getContainerNav = (containerList: any[]) => {
  const containerNavIds = getVerticalNav(
    containerList.map(container => `${PAGE}-${container._meta.id}`),
    {
      parent: HOME_CONTAINERS,
    },
  );

  navMap.HOME_CONTAINERS.forwardFocus = `${PAGE}-${containerList[0]._meta.id}`;

  return {
    containerNavIds,
  };
};

const getItemData = (data: any) => data;

const Home = () => {
  const [pageData, setPageData] = useState<any>();
  const directPublisherConfig = useDirectPublisherConfig();
  const updateScroll = useUpdateScroll(PAGE);
  const moviesData = useAppSelector(getMovies);
  const seriesData = useAppSelector(getSeries);

  const getPageData = useCallback(async () => {
    const containers = await directPublisherConfig.fetchHomeContainers();

    setPageData({
      containers: populateContainers(containers, {
        movies: moviesData,
        tvShows: seriesData,
      }),
    });
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    if (pageData) {
      useNativeFocus();
    }
  }, [pageData]);

  if (!pageData) {
    return <Spinner force />;
  }

  const { containerNavIds } = getContainerNav(pageData?.containers);

  return (
    <Page nav={navMap.PAGE} className={styles.wrapper}>
      <Scroll id={PAGE} extraPush={250}>
        <FocusDiv nav={navMap.HOME_CONTAINERS} className={styles.containers}>
          {pageData?.containers.map((container: any) => {
            return (
              <div key={container?._meta.id}>
                {getContainerComponent(
                  container,
                  containerNavIds[`${PAGE}-${container._meta.id}`],
                  getItemData,
                  { onFocus: updateScroll },
                )}
              </div>
            );
          })}
        </FocusDiv>
      </Scroll>
    </Page>
  );
};

export default Home;
