import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type NavState = {
  preventBack: boolean;
};

const name = 'nav';

const initialState: NavState = {
  preventBack: false,
};

export const {
  reducer,
  actions: { preventDefaultBack },
} = createSlice({
  name,
  initialState,
  reducers: {
    preventDefaultBack(state, { payload }: PayloadAction<boolean>) {
      state.preventBack = payload;
    },
  },
});

let rootSelector: RootSelector<NavState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<NavState> = selector => {
  rootSelector = selector;
};

export const getIsBackPrevented = (state: RootState) =>
  rootSelector(state).preventBack;
