/*
 *** ACCEDO ONE CONFIGURATIONS ***
 *
 *  Configurations for an Accedo One client.
 */

import type { AccedoOneConfig } from '@accedo/accedo-one';
import accedoOne from '@accedo/accedo-one-express';
import _get from 'lodash/get';

export const SESSION_KEY = 'a1_s' as const;
export const DEVICE_ID = 'a1_d' as const;
export const APPLICATION_KEY = 'a1_a' as const;
export const SIXTY_YEARS_IN_MS = 2147483647000 as const; // from node_modules/@accedo/accedo-one-express/src/index.js
const HTTP_ONLY = false as const; // if cookies need to be retrieved from the client, this should be false

const express: AccedoOneConfig = {
  appKey: process.env.ACCEDO_ONE_KEY || '6196c484a0e84500172695ac',
  getRequestInfo: req =>
    Object.assign(accedoOne.defaultGetRequestInfo(req), {
      sessionKey: _get(req, `cookies.${SESSION_KEY}`, ''),
      deviceId: _get(req, `cookies.${DEVICE_ID}`, ''),
    }),
  onSessionKeyChanged: (key, res) => {
    if (!res) {
      return;
    }

    res.cookie(SESSION_KEY, key, {
      httpOnly: HTTP_ONLY,
      maxAge: SIXTY_YEARS_IN_MS,
    });
  },
  onDeviceIdGenerated: (key, res) => {
    if (!res) {
      return;
    }

    res.cookie(DEVICE_ID, key, {
      httpOnly: HTTP_ONLY,
      maxAge: SIXTY_YEARS_IN_MS,
    });
  },
};

export default express;
