const fetchData = async (url: string, options: any) => {
  try {
    const response = await fetch(url, options);

    const data: any = await response.json();

    return data;
  } catch (error) {
    return { hasError: true, error };
  }
};

const orchestraConfig = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
  endpoints: {
    movie: 'http://13.53.194.134:1337/movies/',
    show: 'http://13.53.194.134:1337/tv-shows/',
    popularMovies: 'http://13.53.194.134:1337/movie-playlists/1',
    season: 'http://13.53.194.134:1337/tv-seasons/',
    moviePlaylists: 'http://13.53.194.134:1337/movie-playlists/',
    showPlaylists: 'http://13.53.194.134:1337/tv-show-playlists/',
  },
};

const config = {
  method: orchestraConfig.method,
  headers: orchestraConfig.headers,
};

const useOrchestra = () => {
  return {
    fetchPlaylist: async (url: string) => {
      return fetchData(url, config);
    },
    fetchAssetData: async (id: string, type: string) => {
      let url;

      if (type === 'movie') {
        url = orchestraConfig.endpoints.movie + id;
      } else {
        url = orchestraConfig.endpoints.show + id;
      }

      return fetchData(url, config);
    },
    fetchPopularMovies: async () => {
      const url = orchestraConfig.endpoints.popularMovies;

      return fetchData(url, config);
    },
    fetchSeason: async (id: string) => {
      const url = orchestraConfig.endpoints.season + id;

      return fetchData(url, config);
    },
    fetchPlayslistById: async (id: string, type: string) => {
      let url;

      if (type === 'movie') {
        url = orchestraConfig.endpoints.moviePlaylists + id;
      } else {
        url = orchestraConfig.endpoints.showPlaylists + id;
      }

      return fetchData(url, config);
    },
  };
};

export default useOrchestra;
