import { useEffect, useState, useRef } from 'react';
import { FocusDiv, Page } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';

import { focusManager } from '@accedo/vdkweb-navigation';
import { useAppSelector, useToSidebarNav, useAppDispatch } from '#/hooks';
import {
  getParentalControlData,
  setParentalControlLevel,
} from '#/redux/modules/parentalControl';
import { getVerticalNav } from '#/utils/navigationHelper';
import { PARENTAL_CONTROL } from '#/utils/navigationMap';
import { useNativeFocus } from '#/utils/accessibility';

import styles from './parentalControl.scss';
import ParentalControlItem from './ParentalControlItem';
import ParentalControlPIN from './ParentalControlPIN';
import { PARENTAL_CONTROL_DICTIONARY } from './ParentalControlUtils';

const { PAGE, LIST, PARENTAL_CONTROL_ITEM, PARENTAL_CONTROL_PIN } =
  PARENTAL_CONTROL;
const { PARENTAL_CONTROL_DESCRIPTION } = PARENTAL_CONTROL_DICTIONARY;

const FIRST_ITEM = `${PARENTAL_CONTROL_ITEM}0`;

const PARENTAL_CONTROL_LEVEL = 'parental-control-level';
const PARENTAL_CONTROL_PIN_VALUE = 'parental-control-pin';

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: LIST,
    useLastFocus: true,
  },
  LIST: {
    id: LIST,
    useLastFocus: true,
    forwardFocus: FIRST_ITEM,
  },
};

const buildParentControlNav = (items: any[]) => {
  const parentalItemsNav = getVerticalNav(
    items.map((_, i) => `${PARENTAL_CONTROL_ITEM}${i}`),
    {
      parent: PAGE,
    },
  );

  return { parentalItemsNav };
};

const ParentalControl = () => {
  const parentalItem = useRef<string>('');
  const data = useAppSelector(getParentalControlData);
  const [activeParentalItem, setActiveParentalItem] = useState<any>('2');
  const [showParentalPin, setShowParentalPin] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const pcLevel = localStorage.getItem(PARENTAL_CONTROL_LEVEL);

    setActiveParentalItem(pcLevel || '2');

    focusManager.changeFocus(PAGE);
    useNativeFocus();
  }, []);

  const toggleParentalItem = (index: string) => {
    setShowParentalPin(true);
    focusManager.changeFocus(PARENTAL_CONTROL_PIN);
    parentalItem.current = index;
    useNativeFocus();
  };

  const onParentalPinSave = (pinValue: string) => {
    localStorage.setItem(PARENTAL_CONTROL_PIN_VALUE, pinValue);
    localStorage.setItem(PARENTAL_CONTROL_LEVEL, parentalItem.current);

    dispatch(setParentalControlLevel(parentalItem.current));
    setActiveParentalItem(parentalItem.current);
    setShowParentalPin(false);
    focusManager.changeFocus(PAGE);
    useNativeFocus();
  };

  const onParentalPinCancel = () => {
    setShowParentalPin(false);
    focusManager.changeFocus(PAGE);
    useNativeFocus();
  };

  if (!data) {
    return null;
  }

  const { parentalItemsNav } = buildParentControlNav(data);

  return (
    <Page nav={navMap.PAGE} className={classNames(styles.container)}>
      <FocusDiv nav={navMap.LIST}>
        <p className={styles.title}>PARENTAL CONTROLS</p>
        <div className={styles.parentalList}>
          {data &&
            data.map((item: any, index: number) => {
              return (
                <ParentalControlItem
                  nav={useToSidebarNav(
                    parentalItemsNav?.[`${PARENTAL_CONTROL_ITEM}${index}`],
                  )}
                  onClick={() => toggleParentalItem(index.toString())}
                  key={item.id}
                  checkedIndex={parseInt(activeParentalItem, 10)}
                  displayText={item.displayName}
                  displayDescription={item.displayDescription}
                />
              );
            })}
        </div>
        <p className={styles.parentalDescription}>
          {PARENTAL_CONTROL_DESCRIPTION}
        </p>
        <div
          className={classNames({
            [styles.visible]: showParentalPin,
            [styles.hidden]: !showParentalPin,
          })}
        >
          <ParentalControlPIN
            key={new Date().getTime()}
            nav={{ id: PARENTAL_CONTROL_PIN }}
            onSave={onParentalPinSave}
            onCancel={onParentalPinCancel}
          />
        </div>
      </FocusDiv>
    </Page>
  );
};

export default ParentalControl;
