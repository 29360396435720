import classNames from 'classnames';

import { withFocus } from '@accedo/vdkweb-navigation';

import { useAppSelector } from '#/hooks';
import { getIsLowEndDevice } from '#/redux/modules/system';

import styles from './button.scss';

type Props = {
  onClick?: () => void;
  children?: React.ReactNode | string;
  className?: string;
  classNameFocused?: string;
  accessibilityLabel?: string;
};

const Button = ({
  nav: { id },
  onClick,
  isFocused,
  children,
  className,
  classNameFocused,
  accessibilityLabel,
}: Props & WithFocusProps) => {
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  return (
    <button
      id={id}
      onClick={onClick}
      className={classNames(
        styles.primaryButton,
        className || styles.defaultStyle,
        {
          [classNameFocused || styles.defaultFocused]: isFocused,
          [styles.animation]: !isLowEndDevice,
        },
      )}
      aria-label={accessibilityLabel}
    >
      <div aria-hidden="true">{children}</div>
    </button>
  );
};

export default withFocus(Button);
