import { useCallback } from 'react';

import { setSidebarCollapsed } from '#/redux/modules/menu';

import useAppDispatch from './useAppDispatch';

function useCollapseSidebar() {
  const dispatch = useAppDispatch();

  const collapseSidebar = useCallback(toggle => {
    dispatch(setSidebarCollapsed(toggle));
  }, []);

  return collapseSidebar;
}

export default useCollapseSidebar;
