import { memo, useRef } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { getIsLowEndDevice } from '#/redux/modules/system';
import { useImage, useUpdateEffect, useAppSelector } from '#/hooks';
import Placeholder from '#/static/images/placeholder/placeholder_width.png';
import {
  getProgressPercentange,
  getRandomProgress,
  secondsToHrs,
} from '#/utils/timeUtils';

import styles from './tiles.scss';

// Replace these values with actual data coming from API
const DURATION = 7200;
const MIN_VALUE = 1;
const MAX_VALUE = 7199;

const ContinueWatchingTile = ({
  isFocused,
  clicked,
  data,
}: SwimlaneItemProps<TypeTile>) => {
  const history = useHistory();
  const bgImage = data?.backdrop?.formats?.small?.url || Placeholder;
  const imageLoaded = useImage(bgImage);
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);
  const progressInSeconds = useRef<number>(
    getRandomProgress(MIN_VALUE, MAX_VALUE),
  );
  const progressRef = useRef<any>(
    getProgressPercentange(DURATION, progressInSeconds.current),
  );
  const timeStringRef = useRef<any>(secondsToHrs(progressInSeconds.current));

  useUpdateEffect(() => {
    if (data?.isMovie) {
      history.push(`/details/movie/${data?.id}`);
    } else if (data?.isShow) {
      history.push(`/details/show/${data?.id}`);
    }
  }, [clicked]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.baseTileContainer, {
          [styles.focused]: isFocused,
          [styles.loaded]: imageLoaded,
          [styles.animation]: !isLowEndDevice,
        })}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className={styles.bottomGradient} />

        <div className={styles.timeString}>
          {isFocused && `${timeStringRef.current} left`}
        </div>

        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{
              width: progressRef.current,
            }}
          />
        </div>
      </div>
      <div className={styles.baseTileTitle}>{isFocused && data?.name}</div>
    </div>
  );
};

export default memo(ContinueWatchingTile);
