import { useEffect, useState } from 'react';

import Spinner from '#/components/Spinner/Spinner';

import styles from './splashScreen.scss';

const INITIAL_LOAD_TIME = 3000;

const SplashScreen = () => {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = 'none';

    setTimeout(() => {
      setHide(true);
    }, INITIAL_LOAD_TIME);
  }, []);

  return !hide ? (
    <div className={styles.container}>
      <Spinner force className={styles.spinner} />
    </div>
  ) : null;
};

export default SplashScreen;
