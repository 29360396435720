import classNames from 'classnames';

import redux from '#/redux/modules';
import { useAppSelector } from '#/hooks';

import styles from './spinner.scss';

const {
  spinner: { getShowSpinner },
} = redux;

type Props = {
  className?: string;
  classNameWrapper?: string;
  force?: boolean;
};

const Spinner = ({
  className = '',
  classNameWrapper = '',
  force = false,
}: Props) => {
  const showSpinner = useAppSelector(getShowSpinner);

  return showSpinner || force ? (
    <div className={classNames(styles.wrapper, classNameWrapper)}>
      <div className={classNames(styles.spinner, className)}>Loading :)</div>
    </div>
  ) : null;
};

export default Spinner;
