import { environment, device } from '@accedo/xdk-core';

import config from '#/config';

let SCREEN_RESOLUTION = config.app.defaultScreenDimension;

// update with XDK dimension
environment.addEventListener(environment.DEVICE.ONLOAD, () => {
  const { system } = device;

  if (system) {
    const { width, height } = system.getAppResolution();

    if (width && height) {
      SCREEN_RESOLUTION = { width, height };

      return;
    }
  }

  if (
    typeof global.screen !== 'undefined' &&
    global.screen.width &&
    global.screen.height
  ) {
    SCREEN_RESOLUTION = {
      height: global.screen.height,
      width: global.screen.width,
    };
  }
});

export default () => SCREEN_RESOLUTION || {};
