import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type AppPlayerState = {
  data: any;
  ended: boolean;
  recommendationsPlaylist: any | null;
  previousEpisode: any | null;
  nextEpisode: any | null;
  cc: boolean;
  ccStyles: ccStyles | null;
};

const name = 'appPlayer';
const initialState: AppPlayerState = {
  data: null,
  ended: false,
  recommendationsPlaylist: null,
  previousEpisode: null,
  nextEpisode: null,
  cc: false,
  ccStyles: null,
};

export const {
  reducer,
  actions: {
    setMediaData,
    setMediaEnded,
    setRecommendationsPlaylist,
    setPreviousEpisode,
    setNextEpisode,
    clearMediaData,
    setCCEnabled,
    setCCStyles,
  },
} = createSlice({
  name,
  initialState,
  reducers: {
    setMediaData(state = initialState, { payload }: PayloadAction<any>) {
      state.data = payload;
      state.data.cuePoints = payload.cuepoints.split(',').map(Number);
    },
    setRecommendationsPlaylist(
      state = initialState,
      { payload }: PayloadAction<any[]>,
    ) {
      state.recommendationsPlaylist = payload;
    },
    setPreviousEpisode(
      state = initialState,
      { payload }: PayloadAction<any | null>,
    ) {
      state.previousEpisode = payload;
    },
    setNextEpisode(
      state = initialState,
      { payload }: PayloadAction<any | null>,
    ) {
      state.nextEpisode = payload;
    },
    setMediaEnded(state = initialState, { payload }: PayloadAction<boolean>) {
      state.ended = payload;
    },
    clearMediaData(state = initialState) {
      state.data = null;
      state.previousEpisode = null;
      state.nextEpisode = null;
    },
    setCCEnabled(state = initialState, { payload }: PayloadAction<boolean>) {
      state.cc = payload;
    },
    setCCStyles(state = initialState, { payload }: PayloadAction<ccStyles>) {
      state.ccStyles = payload;
    },
  },
});

let rootSelector: RootSelector<AppPlayerState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<AppPlayerState> = selector => {
  rootSelector = selector;
};

export const getIsMediaDataSet = (state: RootState) =>
  rootSelector(state)?.data !== null;
export const getMediaUrl = (state: RootState) =>
  rootSelector(state)?.data?.sources[0]?.file;
export const getMediaId = (state: RootState) =>
  rootSelector(state)?.data?.mediaid;
export const getDuration = (state: RootState) =>
  rootSelector(state)?.data?.duration;
export const getProgress = (state: RootState) =>
  rootSelector(state)?.data?.progress;
export const getName = (state: RootState) => rootSelector(state)?.data?.name;
export const getTitle = (state: RootState) => rootSelector(state)?.data?.title;
export const getSeriesId = (state: RootState) =>
  rootSelector(state)?.data?.seriesId;
export const getShowTitle = (state: RootState) =>
  rootSelector(state)?.data?.showTitle;
export const getIsEpisode = (state: RootState) =>
  rootSelector(state)?.data?.productionType === 'episode';
export const getSeasonNumber = (state: RootState) =>
  rootSelector(state)?.data?.seasonNumber;
export const getRating = (state: RootState) =>
  rootSelector(state)?.data?.rating;
export const getEpisodeNumber = (state: RootState) =>
  rootSelector(state)?.data?.episodeNumber;
export const getCuePoints = (state: RootState) =>
  rootSelector(state)?.data?.cuePoints;
export const getGenre = (state: RootState) => rootSelector(state)?.data?.genre;
export const getCCUrl = (state: RootState) =>
  rootSelector(state)?.data?.tracks?.find(
    (item: Track) => item.kind === 'captions',
  )?.file;
export const getTracks = (state: RootState) =>
  rootSelector(state)?.data?.tracks;
export const getHasMediaEnded = (state: RootState) =>
  rootSelector(state)?.ended;
export const getPlaylistRecommendations = (state: RootState) =>
  rootSelector(state)?.recommendationsPlaylist;
export const getPreviousEpisode = (state: RootState) =>
  rootSelector(state)?.previousEpisode;
export const getNextEpisode = (state: RootState) =>
  rootSelector(state)?.nextEpisode;
export const getIsCCEnabled = (state: RootState) => rootSelector(state)?.cc;
export const getCCStyles = (state: RootState) => rootSelector(state)?.ccStyles;
