/* eslint-disable import/no-restricted-paths */
import { useToSidebarNav } from '#/hooks';
import Swimlane from '#/components/Swimlane/Swimlane';
import Hero from '#/components/Hero/Hero';
import Banner from '#/components/Banner/Banner';
import {
  PortraitTile,
  LandscapeTile,
  CategoryTile,
  ContinueWatchingTile,
  PORTRAIT,
  LANDSCAPE,
  CATEGORY,
} from '#/components/Tiles';

export const ContainerTemplates = {
  HERO: 'hero',
  BANNER: 'banner',
  PORTRAIT: 'portrait-swimlane',
  LANDSCAPE: 'landscape-swimlane',
  CATEGORY: 'category-swimlane',
  CONTINUE_WATCHING: 'cw-swimlane',
};

type DataSourceFunction = (data: any) => any;

export const getContainerComponent = (
  container: any,
  nav: any,
  getItemData: DataSourceFunction,
  containerProps?: any,
) => {
  let comp;

  switch (container?.template) {
    case ContainerTemplates.PORTRAIT:
    case ContainerTemplates.LANDSCAPE:
    case ContainerTemplates.CATEGORY:
    case ContainerTemplates.CONTINUE_WATCHING:
      comp = getSwimlaneComponent(container, nav, getItemData, containerProps);
      break;
    case ContainerTemplates.HERO:
      comp = getHeroComponent(container, nav, containerProps);
      break;
    case ContainerTemplates.BANNER:
      comp = getBannerComponent(container, nav, containerProps);
      break;
    default:
      break;
  }

  return comp;
};

const getSwimlaneComponent = (
  container: any,
  nav: any,
  getItemData: DataSourceFunction,
  containerProps?: any,
) => {
  let itemType;
  let comp;
  let itemExtraData;
  let swimlaneData = container?.data;

  switch (container?.template) {
    case ContainerTemplates.LANDSCAPE:
      itemType = LANDSCAPE;
      comp = LandscapeTile;
      break;
    case ContainerTemplates.PORTRAIT:
      itemType = PORTRAIT;
      comp = PortraitTile;
      break;
    case ContainerTemplates.CONTINUE_WATCHING:
      itemType = LANDSCAPE;
      comp = ContinueWatchingTile;
      break;
    case ContainerTemplates.CATEGORY:
      itemType = CATEGORY;
      comp = CategoryTile;
      swimlaneData = container?.data;
      break;
    default:
      break;
  }

  if (!swimlaneData?.length) {
    return;
  }

  if (container?.playlistResolved?.movieIds) {
    itemExtraData = { isMovie: true };
  }

  if (container?.playlistResolved?.tvShowIds) {
    itemExtraData = { isShow: true };
  }

  return (
    <Swimlane
      key={nav.id}
      nav={useToSidebarNav(nav)}
      onFocus={() => null}
      data={swimlaneData}
      component={comp}
      playlistUrl={container?.playlistUrl}
      getItemData={getItemData}
      itemType={itemType}
      displayText={container?.displaytext}
      containerItemLimiter={container?.containerItemLimiter}
      withViewMore={container?.withViewMore}
      itemExtraData={itemExtraData}
      {...containerProps}
    />
  );
};

const getHeroComponent = (container: any, nav: any, containerProps?: any) => {
  let itemType;
  const data = container?.data;

  if (!data?.length) {
    return;
  }

  if (container?.playlistResolved?.movieIds) {
    itemType = 'movie';
  }

  if (container?.playlistResolved?.tvShowIds) {
    itemType = 'show';
  }

  return (
    <Hero
      key={nav.id}
      nav={nav}
      onFocus={() => null}
      items={data}
      interval={3}
      withAutoPlay
      itemType={itemType}
      {...containerProps}
    />
  );
};

const getBannerComponent = (container: any, nav: any, containerProps?: any) => {
  return (
    <Banner
      key={nav.id}
      nav={useToSidebarNav(nav)}
      onFocus={() => null}
      data={container}
      {...containerProps}
    />
  );
};
