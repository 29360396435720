import { useEffect, useState, memo, useCallback } from 'react';
import classNames from 'classnames';

import { focusManager } from '@accedo/vdkweb-navigation';
import { environment as sEnv } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { useAppSelector, useUpdateScroll, useAppDispatch } from '#/hooks';
import { getIsLowEndDevice } from '#/redux/modules/system';
import { clearScrollState } from '#/redux/modules/scroll';

import { createRows, createRowId, createGridNavigation } from './gridUtils';
import Row from './Row';
import styles from './grid.scss';

type props = {
  nav: XDKNav;
  data: any[];
  component: React.ComponentType<any>;
  classNameItemWrapper: string;
  maxItemsRow: number;
  defaultFocusId?: string;
  preventFocus?: boolean;
};

const getNewAxis = (elementId: string) => {
  if (!elementId) {
    return 0;
  }

  const curElement = document.getElementById(elementId);

  return -(curElement?.offsetTop || 0);
};

const Grid = ({
  nav,
  data,
  component,
  classNameItemWrapper,
  maxItemsRow,
  defaultFocusId,
  preventFocus,
}: props) => {
  const [rows, setRows] = useState<any[] | null>(null);
  const [gridNavigation, setGridNavigation] = useState<any>(null);
  const [axis, setNewAxis] = useState(0);
  const dispatch = useAppDispatch();
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);
  const updateScroll = useUpdateScroll(nav.id);

  const onBack = ({ id }: { id: string }) => {
    const { BACK } = vKey;

    switch (id) {
      case BACK.id:
        dispatch(clearScrollState(nav.id));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (defaultFocusId) {
      updateScroll(defaultFocusId);
      setNewAxis(getNewAxis(defaultFocusId));
    }

    sEnv.addEventListener(sEnv.SYSTEM.KEYDOWN, onBack);

    return () => {
      sEnv.removeEventListener(sEnv.SYSTEM.KEYDOWN, onBack);
    };
  }, []);

  const onItemFocus = useCallback(() => {
    const currentId = focusManager.getCurrentFocus();

    updateScroll(currentId);

    setNewAxis(getNewAxis(currentId));
  }, []);

  useEffect(() => {
    if (gridNavigation?.length > 0 && !preventFocus) {
      if (!defaultFocusId) {
        focusManager.changeFocus(gridNavigation[0][0].id);
      } else {
        focusManager.changeFocus(defaultFocusId);
      }
    }
  }, [gridNavigation]);

  useEffect(() => {
    const rowsArray = createRows(data, maxItemsRow);

    setRows(rowsArray);
    setGridNavigation(createGridNavigation(rowsArray, nav));
  }, [data]);

  return (
    <>
      {gridNavigation && rows && (
        <FocusDiv
          nav={{
            ...nav,
            forwardFocus:
              gridNavigation?.length > 0 ? gridNavigation[0][0].id : null,
          }}
          className={styles.wrapper}
        >
          <div
            className={classNames({
              [styles.scroll]: !isLowEndDevice,
            })}
            style={{
              transform: `translateY(${axis}px)`,
            }}
          >
            {rows.map((_, idx) => {
              return (
                <Row
                  key={createRowId(idx)}
                  nav={{ id: createRowId(idx), parent: nav.id }}
                  itemsNav={gridNavigation[idx]}
                  data={rows[idx]}
                  component={component}
                  classNameItemWrapper={classNameItemWrapper}
                  onItemFocus={onItemFocus}
                />
              );
            })}
          </div>
        </FocusDiv>
      )}
    </>
  );
};

export default memo(Grid);
