import { getAccedoOneClient } from '#/services/accedoOne/accedoOne';

export const getEntriesById = async (id: string[]): Promise<any[]> => {
  const client = getAccedoOneClient();
  const response = await client?.getEntries({
    id,
    size: 50,
  });

  if (response) {
    return response.entries;
  }

  return [];
};

export const getEntryById = async (id: string) => {
  const client = getAccedoOneClient();

  return client?.getEntryById(id);
};

export const getMainMenu = async () => {
  const { globalMenu: globalMenuKey }: any =
    await getAccedoOneClient()?.getMetadataByKey('globalMenu');

  const entryById = await getAccedoOneClient()?.getEntryById(globalMenuKey);
  const { items }: any = entryById;

  const menuItems = await getEntriesById(items);

  return menuItems;
};

export const getLandingPageId = async () => {
  const { landingPage: landingPageId }: any =
    await getAccedoOneClient()?.getMetadataByKey('landingPage');

  return landingPageId;
};

export const getPageEntry = async (pageId: string): Promise<any> => {
  let pageData: any = null;

  return (
    getEntryById(pageId)
      .then((pageResult: any) => {
        pageData = pageResult;

        return getEntriesById(pageData.containers).then(containers => {
          // Copy the ID property to a more accessible location
          containers.forEach((container: Container) => {
            container.id = container._meta.id;
          });

          return containers;
        });
      })
      .then(containers => {
        return {
          ...pageData,
          containers,
        };
      }) || []
  );
};

export const getMasterPinCode = async () => {
  const { masterPinCode }: any = await getAccedoOneClient()?.getMetadataByKey(
    'masterPinCode',
  );

  return masterPinCode;
};
