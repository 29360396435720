import { Redirect } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';

import ViewContainer from '#/views/ViewContainer/ViewContainer';
import Home from '#/views/Home/Home';
import Details from '#/views/Details/Details';
import Category from '#/views/Category/Category';
import Promotional from '#/views/Promotional/Promotional';
import ParentalControl from '#/views/ParentalControl/ParentalControl';
import ViewMore from '#/views/ViewMore/ViewMore';
import Search from '#/views/Search/Search';
import Player from '#/views/Player/Player';

import routes from '#/utils/routes';

export default [
  {
    component: ViewContainer,
    routes: [
      {
        path: routes.ROOT,
        exact: true,
        component: () => <Redirect to={routes.HOME} />,
      },
      {
        path: routes.HOME,
        component: Home,
      },
      {
        path: routes.DETAILS,
        component: Details,
      },
      {
        path: routes.CATEGORY,
        component: Category,
      },
      {
        path: routes.PROMOTIONAL,
        component: Promotional,
      },
      {
        path: routes.PARENTAL_CONTROL,
        component: ParentalControl,
      },
      {
        path: routes.VIEW_MORE,
        component: ViewMore,
      },
      {
        path: routes.SEARCH,
        component: Search,
      },
      {
        path: routes.PLAYER,
        component: Player,
      },
    ],
  },
] as RouteConfig[];
