import { useEffect } from 'react';

import {
  setAdSettings,
  setDeviceId,
  setTTSEnabled,
} from '#/redux/modules/system';
import { setCCEnabled, setCCStyles } from '#/redux/modules/appPlayer';

import useAppDispatch from './useAppDispatch';

declare global {
  interface Window {
    VIZIO: any;
  }
}

type LibraryAdSettings = {
  IFA: string;
  IFA_TYPE: string;
  LMT: boolean;
};

const companionLibraryUrl =
  'http://localhost:12345/scfs/cl/js/vizio-companion-lib.js';

// Custom hook to load the local companion library
// that exists on device through inline scripting
const useVizioLibrary = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.addEventListener('VIZIO_LIBRARY_DID_LOAD', e => {
      console.warn(e, 'VIZIO LIBRARY DID LOAD');
      const vizioRef = window.VIZIO;

      vizioRef.getDeviceId((deviceId: string) =>
        dispatch(setDeviceId(deviceId)),
      );
      vizioRef.setAdvertiserIDListener((adSettings: LibraryAdSettings) => {
        dispatch(
          setAdSettings({
            id: adSettings.IFA,
            idType: adSettings.IFA_TYPE,
            limitTracking: adSettings.LMT,
          }),
        );
      });
      vizioRef.setClosedCaptionHandler((enabled: boolean) => {
        dispatch(setCCEnabled(enabled));
      });

      document.addEventListener('VIZIO_TTS_ENABLED', () =>
        dispatch(setTTSEnabled(true)),
      );
      document.addEventListener('VIZIO_TTS_DISABLED', () =>
        dispatch(setTTSEnabled(false)),
      );
      vizioRef.setClosedCaptionStyleChangeHandler((ccStyles: any) => {
        dispatch(setCCStyles(ccStyles));
      });
      vizioRef.setContentChangeHandler((contentUrl: any) => {
        window.location.href = contentUrl;
      });
    });

    const script = document.createElement('script');

    script.src = companionLibraryUrl;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.removeEventListener('VIZIO_LIBRARY_DID_LOAD', () => {
        console.warn('REMOVING VIZIO LIBRARY LISTENER');
      });

      document.body.removeChild(script);
    };
  }, []);
};

export default useVizioLibrary;
