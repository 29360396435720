import { useState } from 'react';
import classNames from 'classnames';

import Button from '#/components/Button/Button';

import { Key } from './keyboardUtils';
import styles from './keyboard.scss';

type props = {
  onClick: (id: string) => void;
  item: Key;
  nav: XDKNav;
  className: string;
  classNameFocused: string;
  classNameIcon: string;
  classNameOnFocus?: string;
  accessibilityLabel?: string;
};

const KeyWithIcon = ({
  onClick,
  item: { text, icon, value },
  nav,
  className,
  classNameFocused,
  classNameIcon,
  classNameOnFocus = '',
  accessibilityLabel,
}: props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Button
      nav={nav}
      className={classNames(className)}
      classNameFocused={classNameFocused}
      onClick={() => {
        onClick(value);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      accessibilityLabel={accessibilityLabel}
    >
      {icon ? (
        <img
          src={icon.src}
          className={classNames(classNameIcon, {
            [styles.white]: !isFocused,
            [classNameOnFocus]: isFocused,
          })}
          alt={text}
        />
      ) : (
        text
      )}
    </Button>
  );
};

export default KeyWithIcon;
