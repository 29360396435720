import historyConfig from '#/config/history';

/**
 * This service relies on using the HashHistory. With the HashHistory, the
 * returned action is always POP, so there is no way to know if the history is
 * going forward or backwards.
 *
 * It is a wrapper for the TV history with several assumptions:
 * - No forward key in the TV (as opposed as in the browser)
 * - When going back, this service will be used
 */

type HistoryItem = {
  skip?: boolean;
  content?: any;
  [key: string]: any;
};

const createHistoryService = () => {
  const stack: HistoryItem[] = [];

  let didPressBack: boolean;
  let shouldSetNextToSkip: boolean;
  let unlistenFn: null | (() => void);

  const resetLocalVariables = () => {
    unlistenFn = null;
    didPressBack = false;
    shouldSetNextToSkip = false;

    stack.length = 0;
  };

  resetLocalVariables();

  const pushToStack = (locObj: any, skip: boolean) => {
    stack.push({
      ...locObj,
      skip,
      content: undefined,
    });
  };

  return {
    init() {
      pushToStack(historyConfig.appHistory.location, shouldSetNextToSkip);

      if (unlistenFn !== null) {
        throw new Error('the service has already been initialized');
      }

      unlistenFn = historyConfig.appHistory.listen(locObj => {
        if (didPressBack) {
          stack.pop();
        } else {
          pushToStack(locObj, shouldSetNextToSkip);
        }

        didPressBack = false;
        shouldSetNextToSkip = false;
      });
    },
    setForwardState(stateContent: any) {
      // Handle the case where the Back key is clicked multiple times when on
      // the beginning of the stack
      if (!stack.length) {
        pushToStack(historyConfig.appHistory.location, false);
      }

      stack[stack.length - 1].content = stateContent;
    },
    getBackState() {
      if (!stack.length) {
        return;
      }

      return stack[stack.length - 1].content;
    },
    pushSkipping(url: string) {
      shouldSetNextToSkip = true;
      historyConfig.appHistory.push(url);
    },
    goBack(num = 1) {
      didPressBack = true;
      let totalNum = num;

      for (let i = 0; i < totalNum; i += 1) {
        const stackIndex = stack.length - 2 - i;

        if (stackIndex < 0) {
          break;
        }

        const stackItem = stack[stackIndex];

        if (stackItem && stackItem.skip) {
          totalNum += 1;
        }
      }

      historyConfig.appHistory.go(-totalNum);
    },
    skipCurrent(val = true) {
      const lastItem = stack[stack.length - 1];

      if (lastItem) {
        lastItem.skip = val;
      }
    },
    getStack() {
      return stack;
    },
    deinit() {
      if (unlistenFn) {
        unlistenFn();
      }

      resetLocalVariables();
    },
    listen(listener: any) {
      return historyConfig.appHistory.listen(listener);
    },
  };
};

const historyService = createHistoryService();

export { historyService, createHistoryService };
