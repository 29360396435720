import type { RootState } from '#/redux/reducer';
import { createSlice } from '@reduxjs/toolkit';

const name = 'movies';

// Initial State
const initialState = {
  movies: [],
};

export const {
  reducer,
  actions: { setMoviesReducer },
} = createSlice({
  name,
  initialState,
  reducers: {
    setMoviesReducer(state = initialState, { payload }: any) {
      state.movies = payload;
    },
  },
});

let rootSelector: RootSelector<any> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<any> = selector => {
  rootSelector = selector;
};

export const getMovies = (state: RootState) => rootSelector(state).movies;
