import { useEffect, useState, useCallback } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import { environment } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';

import Button from '#/components/Button/Button';
import { PLAYER_PAGE } from '#/utils/navigationMap';
import PlayIcon from '#/static/images/icons/play.svg';
import PauseIcon from '#/static/images/icons/pause.svg';
import { useTimeout } from '#/hooks';

import styles from './playerControls.scss';
import SeekBar from './SeekBar/SeekBar';

const { CONTROLS, PLAY_PAUSE_BTN, SEEK_BAR } = PLAYER_PAGE;

const HIDE_CONTROLS_TIMEOUT_MS = 3000;

const { PLAY_PAUSE, PLAY, PAUSE, LEFT, RIGHT, RW, FF } = vKey;

const navMap = {
  PLAY_PAUSE_BTN: {
    id: PLAY_PAUSE_BTN,
    parent: CONTROLS,
    nextdown: SEEK_BAR,
  },
  SEEK_BAR: {
    id: SEEK_BAR,
    parent: CONTROLS,
    nextup: PLAY_PAUSE_BTN,
  },
};

type Props = {
  nav: XDKNav;
  playerState: any;
  seekDelta?: number;
  onTogglePlay?: any;
  onSeek?: any;
  play?: any;
  pause?: any;
};

const PlayerControls: React.FC<any> = ({
  playerState,
  onTogglePlay,
  seekDelta = 10,
  onSeek,
  play,
  pause,
}: Props) => {
  const [controlsHidden, setControlsHidden] = useState<boolean>(true);

  const hideControls = useCallback(() => {
    setControlsHidden(true);
  }, []);

  const [startControlsTimeout, cancelControlsTimeout] = useTimeout(
    hideControls,
    HIDE_CONTROLS_TIMEOUT_MS,
  );

  const onKeydown = useCallback(({ id }) => {
    setControlsHidden(false);

    startControlsTimeout();

    switch (id) {
      case PLAY_PAUSE.id:
        onTogglePlay();
        break;
      case PLAY.id:
        play();
        break;
      case PAUSE.id:
        pause();
        break;
      case LEFT.id:
        if (focusManager.getCurrentFocus() === SEEK_BAR) {
          onSeek(-seekDelta);
        }

        break;
      case RIGHT.id:
        if (focusManager.getCurrentFocus() === SEEK_BAR) {
          onSeek(seekDelta);
        }

        break;
      case RW.id:
        if (focusManager.getCurrentFocus() === SEEK_BAR) {
          onSeek(-seekDelta);
        }

        break;
      case FF.id:
        if (focusManager.getCurrentFocus() === SEEK_BAR) {
          onSeek(seekDelta);
        }

        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeydown);

    return () => {
      cancelControlsTimeout();
      environment.removeEventListener(environment.SYSTEM.KEYDOWN, onKeydown);
    };
  }, []);

  useEffect(() => {
    focusManager.changeFocus(PLAY_PAUSE_BTN);
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={{
        opacity: controlsHidden ? 0 : 1,
      }}
    >
      <div className={styles.title}>Big Buck Bunny</div>
      <div className={styles.controls}>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            nav={navMap.PLAY_PAUSE_BTN}
            onClick={onTogglePlay}
            accessibilityLabel={playerState?.isPlaying ? 'Pause' : 'Play'}
          >
            <img
              className={styles.playPause}
              src={playerState?.isPlaying ? PauseIcon : PlayIcon}
              alt="Play/Pause"
            />
          </Button>
        </div>

        <SeekBar
          nav={navMap.SEEK_BAR}
          playerState={playerState}
          seekDelta={seekDelta}
          onSeek={onSeek}
        />
      </div>
    </div>
  );
};

export default PlayerControls;
