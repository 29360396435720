export const PARENTAL_CONTROL = {
  PAGE: 'PARENTAL_CONTROL',
  LIST: 'LIST',
  KEYBOARD: 'KEYBOARD',
  PARENTAL_CONTROL_ITEM: 'PARENTAL_CONTROL_ITEM',
  PARENTAL_CONTROL_PIN: 'PARENTAL_CONTROL_PIN',
} as const;

export const MAIN_MENU = {
  MENU_CONTAINER: 'MENU_CONTAINER',
  MENU: 'MAIN_MENU',
  ITEM: 'MENU_ITEM',
};

export const HOME_PAGE = {
  PAGE: 'HOME_PAGE',
  HOME_CONTAINERS: 'HOME_CONTAINERS',
};

export const CATEGORY_PAGE = {
  PAGE: 'CATEGORY_PAGE',
  CATEGORY_CONTAINERS: 'CATEGORY_CONTAINERS',
};

export const PROMOTIONAL_PAGE = {
  PAGE: 'PROMOTIONAL_PAGE',
  PROMOTIONAL_CONTAINERS: 'PROMOTIONAL_CONTAINERS',
};

export const DETAILS_PAGE = {
  PAGE: 'DETAILS_PAGE',
  HEADER: 'HEADER',
  PLAY_BTN: 'PLAY_BTN',
  POPULAR_MOVIES: 'POPULAR_MOVIES',
  SEASONS: 'SEASONS',
};

export const PARENTAL_CONTROL_PAGE = {
  PAGE: 'PARENTAL_CONTROL_PAGE',
};

export const PLAYER_PAGE = {
  PAGE: 'PLAYER_PAGE',
  CONTROLS: 'PLAYER_CONTROLS',
  PLAY_PAUSE_BTN: 'PLAY_PAUSE_BTN',
  SEEK_BAR: 'SEEK_BAR',
};

export const VIEW_MORE_PAGE = {
  PAGE: 'VIEW_MORE_PAGE',
  GRID: 'GRID',
};

export const SEARCH_PAGE = {
  PAGE: 'SEARCH_PAGE',
  KEYBOARD: 'KEYBOARD',
  ACTIVE_COMPONENT: 'ACTIVE_COMPONENT',
};
