import type { History } from 'history';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise';

import type { AppServices } from '@accedo/accedo-one';
import { configureStore } from '@reduxjs/toolkit';

import historyConfig from '#/config/history';
import environment from '#/config/env';

import { rootReducer } from './reducer';
import analyticsMiddleware from './middleware/analytics';

let initState: any;

const { current }: { current: EnvironmentDev | EnvironmentProd } = environment;

if (typeof window === 'object') {
  initState = window?.__data;
} else {
  initState = {};
}

export const getStore = ({
  historyType = historyConfig.appHistory,
  initialState = initState,
  services,
}: {
  historyType?: History<unknown>;
  initialState?: any;
  services: AppServices | null;
}) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: !current.isProduction,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().prepend(
        // The thunk middleware handles thunk actions,
        // typically used for batching actions together and
        // provide async functionality such as making server
        // requests, etc.
        // All thunk actions will get access to our
        // collected services via an extra parameter.
        // Thus we can connect them to any service we want.
        thunkMiddleware.withExtraArgument({ accedoOne: services }),

        // The promise middleware manages actions which return
        // a promise. It will then handle resolve and reject
        promiseMiddleware,

        analyticsMiddleware,
      ),
  });

  return {
    store,
    history: historyType,
  };
};
