import { Provider as StateProvider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import type { EnhancedStore } from '@reduxjs/toolkit';

import SplashScreen from '#/components/SplashScreen/SplashScreen';

// Import XDK4 Preflight loader for side effect only here.
// i.e. the client load the file, evaluate it, and eventually
// load xdk.config.js into the XDK4 config store.
import './preflight';

// And the preflight loader NEEDS to happen BEFORE the app itself
// loading the XDK4 Core module, which is going to happen in
// Viki's AppShell.

import AppShell from './AppShell';

type Props = {
  store: EnhancedStore;
  content: React.ReactNode;
};

const App = ({ store, content }: Props) => (
  <StateProvider store={store}>
    <StylesProvider injectFirst>
      <SplashScreen />
      <AppShell>{content}</AppShell>
    </StylesProvider>
  </StateProvider>
);

export default App;
