import { useState, useEffect, useCallback, useRef } from 'react';

import { usePrevious, useLatest } from '#/hooks';

import SwimlaneItemWrapper from './SwimlaneItemWrapper';

type Props<ItemDataType> = {
  items: SwimlaneItemWrapperProps<ItemDataType>[];
  axis?: number;
  hideOverflow?: boolean;
  swimlaneId: string;
  removeItemsOffScreen: boolean;
  onSwimlaneReady?: (swimlaneId: string) => void;
};

function getSwimlaneRelativeSpace(swimlaneId: string = '') {
  const swimlaneElement = document.getElementById(swimlaneId);

  if (!swimlaneElement) {
    return 0;
  }

  return swimlaneElement.offsetWidth;
}

const VisualWindow = <ItemDataType,>({
  items = [],
  axis = 0,
  swimlaneId,
  removeItemsOffScreen,
  onSwimlaneReady,
}: Props<ItemDataType>) => {
  const [itemsReady, setItemsReady] = useState(false);
  const prevItemsReady = usePrevious(itemsReady);

  const latestAxisRef = useLatest(axis);
  const latestItemsRef = useLatest(items);
  const swimlaneRelativeSpaceRef = useRef(0);

  const isVisible = useCallback((i: number) => {
    const latestAxis = latestAxisRef.current;
    const latestItems = latestItemsRef.current;
    const itemElement = document.getElementById(latestItems[i].nav.id);

    if (!itemElement) {
      return false;
    }

    if (!swimlaneRelativeSpaceRef.current) {
      swimlaneRelativeSpaceRef.current = getSwimlaneRelativeSpace(swimlaneId);
    }

    const swimlaneRelativeSpace = swimlaneRelativeSpaceRef.current;
    const minVisibleSpace = -latestAxis;
    const itemLeft = itemElement.offsetLeft;
    const itemWidth = itemElement.offsetWidth;
    // We add twice the itemWidth to extend visible zone
    const itemRight = itemLeft + itemWidth * 2;

    const isItemBelowVisibleSpace = minVisibleSpace > itemRight;
    // We substract itemWidth from itemLeft to extend visible zone
    const isItemBeyondVisibleSpace =
      swimlaneRelativeSpace - latestAxis < itemLeft - itemWidth;

    if (isItemBelowVisibleSpace || isItemBeyondVisibleSpace) {
      return false;
    }

    return true;
  }, []);

  useEffect(() => {
    setItemsReady(true);

    return () => {
      setItemsReady(false);
    };
  }, [items]);

  useEffect(() => {
    if (itemsReady && !prevItemsReady) {
      onSwimlaneReady?.(swimlaneId);
    }
  }, [itemsReady]);

  return (
    <>
      {items.map(item => (
        <SwimlaneItemWrapper
          {...item}
          inVisibleArea={
            removeItemsOffScreen ? itemsReady && isVisible(item.index) : true
          }
        />
      ))}
    </>
  );
};

export default VisualWindow;
