import { useCallback } from 'react';

import { setSidebarActive } from '#/redux/modules/menu';

import useAppDispatch from './useAppDispatch';

function useToggleMenu() {
  const dispatch = useAppDispatch();

  const toggleMenu = useCallback(toggle => {
    dispatch(setSidebarActive(toggle));
  }, []);

  return toggleMenu;
}

export default useToggleMenu;
