export const createRows = (initialArray: any[], chunkSize: number): any[] => {
  const finalArray: any[] = [];

  return finalArray.concat.apply(
    [],
    initialArray.map((_, idx) => {
      return idx % chunkSize ? [] : [initialArray.slice(idx, idx + chunkSize)];
    }),
  );
};

export const createItemId = (id: any): string => {
  return `GRID-ITEM-${id}`;
};

export const createRowId = (id: any): string => {
  return `ROW-${id}`;
};

export const createGridNavigation = (array: any, nav: XDKNav): any => {
  if (!array) {
    return;
  }

  const finalGridPositions = [];

  for (let i = 0; i < array.length; i += 1) {
    const row = [];

    for (let j = 0; j < array[i].length; j += 1) {
      const item: XDKNav = {
        id: createItemId(array[i][j].id),
        nextup: i === 0 ? nav.nextup : '',
        parent: createRowId(i),
      };

      // Check left
      if (j > 0) {
        item.nextleft = createItemId(array[i][j - 1].id);
      }

      // Check up
      if (i > 0) {
        item.nextup = createItemId(array[i - 1][j].id);
      }

      // Check right
      if (j < array[i].length - 1) {
        item.nextright = createItemId(array[i][j + 1].id);
      }

      // Check down
      if (i < array.length - 1) {
        if (j <= array[i + 1].length - 1) {
          item.nextdown = createItemId(array[i + 1][j].id);
        } else {
          item.nextdown = createItemId(
            array[i + 1][array[i + 1].length - 1].id,
          );
        }
      }

      row.push(item);
    }

    finalGridPositions.push(row);
  }

  return finalGridPositions;
};
