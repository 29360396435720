import { ParentalControlItem } from '#/redux/modules/parentalControl';

export const PARENTAL_CONTROL_PIN = {
  INPUT: 'INPUT_PIN',
  PIN_KEYBOARD: 'PIN_KEYBOARD',
  OK: 'Ok',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  DELETE: 'DELETE_PIN',
};

export const PARENTAL_CONTROL_DICTIONARY = {
  PARENTAL_CONTROL_DESCRIPTION:
    'You will need to enter a 4-digit number each time you want to change an appropriate viewing age.',
  ENTER_PIN_TITLE: 'Enter Parental Control PIN',
  FIRST_PIN_TITLE: 'Create Parental Control PIN',
  WRONG: 'Wrong PIN entered',
  WRONG_ATTEMPT_REACHED: 'Too many attempts. Please try again later.',
  NEED_HELP: 'Need help? Visit support.vizio.com',
};

export type ParentalControlStorage = {
  ParentalControlLevel: string;
  ParentalControlPin: string;
};

type ParentalControlLevels = {
  [key: string]: string;
};

export const parentalControlLevels: ParentalControlLevels = {
  0: '2',
  1: '1',
  2: '0',
};

export const getParentalRestrictions = (
  parentalControlData: ParentalControlItem[],
  selectedParentalOption: string,
  isQuery = true,
) => {
  const restrictions: string[] = [];

  if (selectedParentalOption && parentalControlData.length > 0) {
    const option = parseInt(selectedParentalOption, 10);

    if (option === parentalControlData.length - 1) {
      return restrictions;
    }

    for (let i = parentalControlData.length - 1; i > option; i -= 1) {
      parentalControlData[i].ratings?.forEach((el: any) => {
        isQuery
          ? restrictions.push(`rating:${el.rating}`)
          : restrictions.push(el.rating);
      });
    }
  }

  return restrictions;
};

export const filterData = (data: any[], restrictions: string[]) => {
  const filteredData: any[] = [];

  data.forEach((item: any) => {
    if (!restrictions.some(el => el === item.rating)) {
      filteredData.push(item);
    }
  });

  return filteredData;
};
