import { useState, useCallback, useRef, useEffect, memo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { focusManager } from '@accedo/vdkweb-navigation';

import { useAppSelector, useImage, useInterval } from '#/hooks';
import { getIsLowEndDevice } from '#/redux/modules/system';
import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { MAIN_MENU } from '#/utils/navigationMap';
import LeftGradient from '#/static/images/gradients/soft-gradient-left.png';
import VerticalGradient from '#/static/images/gradients/soft-gradient-vertical.png';
import { composeHeroLabel } from '#/utils/accessibility';

import styles from './hero.scss';

type Props = {
  items: any[];
  onFocus?: any;
  nav?: any;
  interval?: number | undefined;
  withAutoPlay?: boolean;
  itemType?: string;
};

const { MENU_CONTAINER } = MAIN_MENU;

const composeHeroNav = (
  nav: any,
  nextCallback: () => void,
  prevCallback: () => void,
) => {
  return {
    ...nav,
    internal: {
      nextright: nextCallback,
      nextleft: prevCallback,
    },
  };
};

const Hero = ({
  nav,
  items,
  onFocus,
  interval,
  withAutoPlay,
  itemType,
}: Props) => {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState<boolean>();
  const autoPlayRef = useRef<any>();
  const isScreenMounted = useRef(true);
  const bgImage = items[activeIndex]?.backdrop?.formats?.medium?.url;
  const imageLoaded = useImage(bgImage);
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  const AUTO_PLAY_INTERVAL = interval ? interval * 1000 : 0;

  const onClick = useCallback(() => {
    if (itemType === 'movie') {
      history.push(`/details/movie/${items[activeIndex]?.id}`);
    } else if (itemType === 'show') {
      history.push(`/details/show/${items[activeIndex]?.id}`);
    }
  }, [activeIndex]);

  const getBgImage = useCallback((index: number) => {
    return items && items[index]?.backdrop?.formats?.medium?.url;
  }, []);

  const nextItem = useCallback(() => {
    if (isScreenMounted.current) {
      if (activeIndex === items.length - 1) {
        return setActiveIndex(0);
      }

      setActiveIndex(activeIndex + 1);
    }
  }, [activeIndex]);

  const prevItem = useCallback(() => {
    if (isScreenMounted.current) {
      if (activeIndex === 0) {
        return focusManager.changeFocus(MENU_CONTAINER);
      }

      setActiveIndex(activeIndex - 1);
    }
  }, [activeIndex]);

  useEffect(() => {
    return () => {
      isScreenMounted.current = false;
      setPaused(true);
    };
  }, [isScreenMounted]);

  useEffect(() => {
    if (isScreenMounted.current) {
      autoPlayRef.current = nextItem;
    }
  });

  const autoPlay = () => {
    return autoPlayRef.current();
  };

  const [startAutoPlay, clearAutoPlay] = useInterval(
    autoPlay,
    AUTO_PLAY_INTERVAL,
  );

  const pause = useCallback(() => {
    if (withAutoPlay) {
      clearAutoPlay();
    }

    onFocus();
    setPaused(true);
  }, []);

  const play = useCallback(() => {
    if (withAutoPlay) {
      startAutoPlay();
    }

    setPaused(false);
  }, []);

  return (
    <FocusDiv
      nav={composeHeroNav(nav, nextItem, prevItem)}
      className={styles.wrapper}
      onClick={onClick}
      onFocus={pause}
      onBlur={play}
      aria-label={composeHeroLabel(items[activeIndex]?.name)}
    >
      <div aria-hidden="true">
        <img
          src={LeftGradient}
          alt="Horizontal Gradient"
          className={styles.gradient}
        />

        <img
          src={VerticalGradient}
          alt="Vertical Gradient"
          className={styles.gradient}
        />

        <div>
          {items.map((_, i: number) => (
            <div
              key={`${nav.id}-${i}`}
              className={classNames(styles.image, {
                [styles.active]: activeIndex === i,
                [styles.loaded]: imageLoaded,
                [styles.animation]: !isLowEndDevice,
              })}
              style={{
                backgroundImage:
                  activeIndex === i ? `url(${getBgImage(i)})` : 'none',
              }}
            />
          ))}

          <div className={styles.title}>{items[activeIndex]?.name}</div>

          <div
            className={classNames(styles.button, {
              [styles.focused]: paused,
              [styles.animation]: !isLowEndDevice,
            })}
          >
            View Details
          </div>

          <div className={styles.dots}>
            {items.map((_, i: number) => (
              <div
                key={i}
                className={classNames(styles.dot, {
                  [styles.activeDot]: activeIndex === i,
                })}
              />
            ))}
          </div>
        </div>
      </div>
    </FocusDiv>
  );
};

export default memo(Hero);
