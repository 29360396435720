import type { Reducer } from '@reduxjs/toolkit';

import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';

type ReduxModule = {
  reducer?: Reducer;
  setRootSelector?: SetRootSelector;
  selectors?: {
    setRootSelector?: SetRootSelector;
  };
};
type ReduxModules = Record<string, ReduxModule>;

// Exporting root state selectors for our modules so the
// components don't have to know about the state shape.
const createAndAddRootSelector = (
  module: ReduxModule,
  moduleName: string,
  mountPoint: string,
) => {
  const setRootSelector =
    (module?.selectors && module.selectors.setRootSelector) ||
    module.setRootSelector;

  if (!setRootSelector) {
    return;
  }

  if (mountPoint) {
    setRootSelector(
      (state: Record<string, any>) => state[mountPoint][moduleName],
    );
  } else {
    setRootSelector((state: Record<string, any>) => state[moduleName]);
  }
};

export const registerModules = (modules: ReduxModules, mountPoint: string) => {
  forEach(modules, (module, moduleName) =>
    createAndAddRootSelector(module, moduleName, mountPoint),
  );
};

export const extractReducers = <Modules extends ReduxModules>(
  modules: Modules,
): any => {
  return reduce(
    modules,
    (acc, module, name) => {
      // We don't care about modules without reducers
      if (!module || !module.reducer) {
        return acc;
      }

      // Merge the reducer into the combined object
      return {
        ...acc,
        [name]: module.reducer,
      };
    },
    {},
  );
};
