import { OverflowTile, OVERFLOW } from '#/components/Tiles';

type ItemMetaParser<ItemDataType, ItemExtraData> = {
  data: (ItemDataType | OverflowType)[];
  getItemData?: (
    item: ItemDataType | OverflowType,
  ) => ItemDataType | OverflowType;
  swimlaneId: string;
  itemType: string;
  title: string;
  itemExtraData?: ItemExtraData;
  onItemFocus: (index: number) => any;
  component: React.ComponentType;
};

export const composeItemId = (swimlaneId: string, index: number) =>
  `${swimlaneId}-ITEM-${index}`;

export const getItemsProps = <ItemDataType, ItemExtraData>({
  data,
  getItemData,
  swimlaneId,
  itemType,
  title,
  itemExtraData,
  onItemFocus,
  component,
}: ItemMetaParser<ItemDataType, ItemExtraData>): SwimlaneItemWrapperProps<
  ItemDataType | ItemExtraData | OverflowType
>[] => {
  function isOverflowType(
    item: ItemDataType | OverflowType,
  ): item is OverflowType {
    return (item as OverflowType).isViewMore === true;
  }

  return data.map((item, i) => {
    const isFirstItem = i === 0;
    const isLastItem = i === data.length - 1;
    const id = composeItemId(swimlaneId, i);
    const itemData = getItemData ? getItemData(item) : item;
    const nav = {
      id,
      parent: swimlaneId,
      ...(!isFirstItem && { nextleft: composeItemId(swimlaneId, i - 1) }),
      ...(!isLastItem && { nextright: composeItemId(swimlaneId, i + 1) }),
    };

    const componentType = isOverflowType(item) ? OverflowTile : component;

    return {
      nav,
      swimlaneId,
      onItemFocus,
      component: componentType,
      type: itemType,
      title,
      key: id,
      index: i,
      className: isOverflowType(item) ? `${itemType} ${OVERFLOW}` : itemType,
      data: {
        ...itemData,
        ...itemExtraData,
      },
    };
  });
};
