import classNames from 'classnames';

import styles from './PinInput.scss';

type Props = {
  id?: string;
  value?: string;
  type?: string;
  className?: string;
  classNameFocused?: string;
};

const PinInput = ({ id, value, type = 'text', className }: Props) => {
  return (
    <input
      id={id}
      defaultValue={type === 'password' && value ? '*' : value}
      className={classNames(styles.input, className, {
        [styles.defaultStyle]: type !== 'password',
        [styles.specialStyle]: type === 'password',
      })}
    />
  );
};

export default PinInput;
