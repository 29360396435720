type Props = {
  domRef?: React.Ref<HTMLVideoElement>;
  onTimeUpdate?: any;
  width?: number;
  height?: number;
};

const MEDIA_URL =
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';

const VideoPlayer = ({ onTimeUpdate, domRef = null, height, width }: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      autoPlay
      onTimeUpdate={onTimeUpdate}
      ref={domRef}
      width={width}
      height={height}
    >
      <source src={MEDIA_URL} type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
