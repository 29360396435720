import type { RootState } from '#/redux/reducer';
import { createSlice } from '@reduxjs/toolkit';
// Action Types

const name = 'series';

// Initial State
const initialState = {
  series: [],
};

export const {
  reducer,
  actions: { setSeriesReducer },
} = createSlice({
  name,
  initialState,
  reducers: {
    setSeriesReducer(state = initialState, { payload }: any) {
      state.series = payload;
    },
  },
});

let rootSelector: RootSelector<any> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<any> = selector => {
  rootSelector = selector;
};

export const getSeries = (state: RootState) => rootSelector(state).series;
