import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useAccedoOneConfig } from '#/hooks';

import styles from './logo.scss';

type Props = {
  className?: string;
};

const Logo = ({ className = '' }: Props) => {
  const [, appConfig] = useAccedoOneConfig();
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    const { appLogo } = appConfig.assetsResolved;

    if (appLogo) {
      setImgSrc(appLogo);
    }
  }, [appConfig]);

  return (
    <img
      src={imgSrc}
      className={classNames(styles.logo, className)}
      alt="Logo"
    />
  );
};

export default Logo;
