import { memo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { getIsLowEndDevice } from '#/redux/modules/system';
import { useUpdateEffect, useAppSelector } from '#/hooks';
import { composeCategoryTileLabel } from '#/utils/accessibility';

import styles from './tiles.scss';

const CategoryTile = ({
  isFocused,
  clicked,
  data,
}: SwimlaneItemProps<TypeTile>) => {
  const history = useHistory();
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  useUpdateEffect(() => {
    history.push(`/category/${data?.page}`);
  }, [clicked]);

  return (
    <div className={styles.wrapper}>
      <div aria-label={composeCategoryTileLabel(data?.displayText)} />
      <div
        className={classNames(styles.baseTileContainer, {
          [styles.focused]: isFocused,
          [styles.animation]: !isLowEndDevice,
        })}
        aria-hidden="true"
      >
        <div className={styles.internalText}>{data?.displayText}</div>
      </div>
    </div>
  );
};

export default memo(CategoryTile);
