const routes = {
  ROOT: '/',
  HOME: '/home',
  DETAILS: '/details/:type/:assetId',
  CATEGORY: '/category/:pageId',
  PROMOTIONAL: '/promotional/:pageId',
  PARENTAL_CONTROL: '/parental-control/:pageId?',
  VIEW_MORE: '/view-more/:title/:type/:asset/:playlistId',
  SEARCH: '/search',
  PLAYER: '/player',
} as const;

export default routes;
