import classNames from 'classnames';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import Button from '#/components/Button/Button';
import KeyWithIcon from '#/components/Keyboard/KeyWithIcon';

import { KEYS } from './pinKeyMap';
import styles from './pinKeyboard.scss';

const FIRST_KEY = KEYS[0][0].nav.id;

type Props = {
  updateScroll?: () => void;
  onClick: (a: string) => void;
  nav: XDKNav;
};

const PinKeyboard = ({ nav, onClick, updateScroll }: Props) => {
  let focusKeyboard;

  if (updateScroll) {
    focusKeyboard = () => {
      updateScroll();
    };
  }

  const onClickSpecialKeys = (value: string): void => {
    onClick(value);
  };

  return (
    <FocusDiv
      onFocus={focusKeyboard}
      nav={{ ...nav, forwardFocus: FIRST_KEY }}
      className={styles.wrapper}
    >
      {KEYS.map((row, id) => {
        return (
          <div key={id} className={styles.row}>
            {row.map(item => {
              const keyNav = {
                ...item.nav,
                nextdown: item.nav.nextdown,
                parent: nav.id,
              };

              return item.icon ? (
                <KeyWithIcon
                  key={item.text}
                  nav={keyNav}
                  item={item}
                  className={styles.key}
                  classNameFocused={styles.keyFocused}
                  classNameIcon={styles.buttonIcon}
                  onClick={onClickSpecialKeys}
                  accessibilityLabel={item.accessibilityLabel}
                />
              ) : (
                <Button
                  key={item.text}
                  nav={keyNav}
                  className={classNames(styles.key, {
                    [styles.specialKeySize]: item.special,
                    [styles.defaultKeySize]: !item.special,
                    [styles.buttonHidden]: item.text === '',
                  })}
                  classNameFocused={styles.keyFocused}
                  onClick={() => {
                    onClickSpecialKeys(item.value);
                  }}
                  accessibilityLabel={item.accessibilityLabel}
                >
                  {item.text}
                </Button>
              );
            })}
          </div>
        );
      })}
    </FocusDiv>
  );
};

export default PinKeyboard;
