import type { Client, ConfigurationService } from '@accedo/accedo-one';

import config from '#/config';

// use accedo one as configuration service
export const getAccedoOneService = (
  accedoOneClient: Client,
): ConfigurationService => ({
  get: () => {
    return Promise.all([
      accedoOneClient.getAllMetadata(),
      accedoOneClient.getAllAssets(),
    ])
      .then(async ([metadata, assets]) => {
        return {
          ...config.app,
          accedoOne: {
            ...metadata,
            assets,
          },
        };
      })
      .catch(() => {
        return config.app;
      });
  },
});
