import { useEffect, useCallback } from 'react';

import { fetchStatus, getIsFetching, getStatus } from '#/redux/modules/status';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

function useConfig() {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(getIsFetching);
  const status = useAppSelector(getStatus);
  const loaded = !!(!isFetching && status);

  const loadStatus = useCallback(() => {
    if (!isFetching) {
      dispatch(fetchStatus());
    }
  }, [isFetching]);

  useEffect(() => {
    loadStatus();
  }, []);

  return [loaded, status] as const;
}

export default useConfig;
