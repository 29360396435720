import { useCallback, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import { focusManager } from '@accedo/vdkweb-navigation';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import PinInput from '#/components/PinInput/PinInput';
import Button from '#/components/Button/Button';
import { getMasterPinCode } from '#/utils/getAccedoOneData';
import { useNativeFocus, useChromevox } from '#/utils/accessibility';
import ErrorIcon from '#/static/images/icons/error.svg';
import PinKeyboard from '../../components/PinKeyboard/pinKeyboard';
import styles from './parentalControl.scss';
import {
  PARENTAL_CONTROL_DICTIONARY,
  PARENTAL_CONTROL_PIN,
} from './ParentalControlUtils';

type Props = {
  nav: XDKNav;
  onSave: (inputValue: string) => void;
  onCancel: () => void;
};

type Labels = {
  title: string;
  buttonText: string;
};

const { PIN_KEYBOARD, OK, SAVE, CANCEL, DELETE } = PARENTAL_CONTROL_PIN;

const PARENTAL_CONTROL_PIN_VALUE = 'parental-control-pin';

const {
  FIRST_PIN_TITLE,
  ENTER_PIN_TITLE,
  WRONG,
  WRONG_ATTEMPT_REACHED,
  NEED_HELP,
} = PARENTAL_CONTROL_DICTIONARY;

const insertChar = (str: string, char: string): string => {
  return str.substring(0, str.length) + char;
};

const ParentalControlPIN = ({ nav, onSave, onCancel }: Props) => {
  const [labels, setLabels] = useState<Labels>({
    title: FIRST_PIN_TITLE,
    buttonText: SAVE,
  });
  const masterPin = useRef<string>('');
  const wrongAttemps = useRef<number>(0);
  const [wrongLabel, setWrongLabel] = useState<string>('');

  const [inputValue, setInputValue] = useState<string>('');
  const inputs = document.querySelectorAll('#pinInput > *[id]');
  const inputDirection = useRef<number>(0);

  const parentalPin = useRef<string | null>(null);

  const keyInput = (keyValue: string) => {
    const lastInput = inputValue;

    switch (keyValue) {
      case DELETE:
        if (lastInput.length === 0) {
          return;
        }

        setInputValue(lastInput.slice(0, lastInput.length - 1));
        inputDirection.current = -1;

        break;

      default:
        if (lastInput.length < 4) {
          setInputValue(insertChar(lastInput, keyValue));
          inputDirection.current = 1;
        }
    }
  };

  useEffect(() => {
    if (inputs) {
      if (inputDirection.current > 0 && inputValue.length < 4) {
        inputs[inputValue.length].classList.add(styles.pinInputFocused);
        inputs[inputValue.length - 1].classList.remove(styles.pinInputFocused);
      }

      if (inputDirection.current < 0) {
        inputs[inputValue.length].classList.add(styles.pinInputFocused);

        if (inputValue.length + 1 <= 3) {
          inputs[inputValue.length + 1].classList.remove(
            styles.pinInputFocused,
          );
        }
      }
    }
  }, [inputValue]);

  const setPin = () => {
    if (inputValue.length === 4) {
      // Save the pin directly (first time/pin reset)
      if (labels.title === FIRST_PIN_TITLE) {
        onSave(inputValue);
        useNativeFocus();

        return;
      }

      if (parentalPin.current && parentalPin.current !== '') {
        if (inputValue === parentalPin.current) {
          onSave(inputValue);
          useNativeFocus();
        } else if (inputValue === masterPin.current) {
          // Pin reset requested by using the master pin, reset the screen
          setLabels({
            title: FIRST_PIN_TITLE,
            buttonText: SAVE,
          });
          setWrongLabel('');
          inputs[inputValue.length - 1].classList.remove(
            styles.pinInputFocused,
          );
          inputs[0].classList.add(styles.pinInputFocused);
          inputDirection.current = 0;
          setInputValue('');
        } else {
          wrongAttemps.current += 1;

          // Block user input attempt after 5 times.
          if (wrongAttemps.current > 4) {
            setWrongLabel(WRONG_ATTEMPT_REACHED);
            useChromevox(`Error: ${WRONG_ATTEMPT_REACHED}`);
            focusManager.changeFocus(CANCEL);
          } else {
            setWrongLabel(WRONG);
            useChromevox(`Error: ${WRONG}`);
          }
        }
      }
    }
  };

  const onCancelPressed = (): void => {
    onCancel();
    useNativeFocus();
  };

  const focusKeyboard = useCallback(() => {
    focusManager.changeFocus(PIN_KEYBOARD);
  }, []);

  const getMasterPin = useCallback(async () => {
    const externalMasterPin = await getMasterPinCode();

    masterPin.current = externalMasterPin;
  }, []);

  useEffect(() => {
    getMasterPin();

    const pcPinValue = localStorage.getItem(PARENTAL_CONTROL_PIN_VALUE);

    parentalPin.current = pcPinValue;

    if (parentalPin.current) {
      setLabels({
        title: ENTER_PIN_TITLE,
        buttonText: OK,
      });
    } else {
      setLabels({
        title: FIRST_PIN_TITLE,
        buttonText: SAVE,
      });
    }
  }, []);

  const splittedInput = inputValue.split('');

  return (
    <div className={styles.parentalPinWrapper}>
      <div className={styles.parentalPinContainer}>
        <span className={styles.parentalPinTitle}>{labels.title}</span>
        <>
          <div id="pinInput" className={styles.pinInputWrapper}>
            <PinInput
              className={styles.pinInputFocused}
              id="first"
              value={splittedInput[0]}
              type={labels.title === ENTER_PIN_TITLE ? 'password' : 'text'}
            />
            <PinInput
              id="second"
              value={splittedInput[1]}
              type={labels.title === ENTER_PIN_TITLE ? 'password' : 'text'}
            />
            <PinInput
              id="third"
              value={splittedInput[2]}
              type={labels.title === ENTER_PIN_TITLE ? 'password' : 'text'}
            />
            <PinInput
              id="fourth"
              value={splittedInput[3]}
              type={labels.title === ENTER_PIN_TITLE ? 'password' : 'text'}
            />
          </div>
          <span
            className={classNames(styles.wrongLabel, {
              [styles.visible]: wrongLabel !== '',
              [styles.hidden]: wrongLabel === '',
            })}
          >
            <img src={ErrorIcon} alt="ErrorIcon" className={styles.errorIcon} />
            {wrongLabel === WRONG ? WRONG : WRONG_ATTEMPT_REACHED}
          </span>
          <div
            className={classNames(styles.pinKeyboardWrapper, {
              [styles.enable]: wrongLabel !== WRONG_ATTEMPT_REACHED,
              [styles.disable]: wrongLabel === WRONG_ATTEMPT_REACHED,
            })}
          >
            <FocusDiv nav={{ id: nav.id }} onFocus={focusKeyboard}>
              <PinKeyboard
                nav={{ id: PIN_KEYBOARD, nextdown: SAVE, useLastFocus: true }}
                onClick={keyInput}
              />
            </FocusDiv>
          </div>
          <Button
            className={classNames(styles.pinButton, {
              [styles.visibleBlock]: wrongLabel !== WRONG_ATTEMPT_REACHED,
              [styles.hiddenBlock]: wrongLabel === WRONG_ATTEMPT_REACHED,
            })}
            nav={{ id: SAVE, nextup: nav.id, nextdown: CANCEL }}
            classNameFocused={styles.pinButtonFocused}
            onClick={setPin}
            accessibilityLabel={labels.buttonText}
          >
            {labels.buttonText}
          </Button>
          <Button
            nav={{
              id: CANCEL,
              nextup: wrongLabel === WRONG_ATTEMPT_REACHED ? '' : SAVE,
            }}
            className={styles.pinButton}
            classNameFocused={styles.pinButtonFocused}
            onClick={onCancelPressed}
            accessibilityLabel={CANCEL}
          >
            {CANCEL}
          </Button>
          <span
            className={classNames(styles.support, {
              [styles.visible]: wrongLabel === WRONG_ATTEMPT_REACHED,
              [styles.hidden]: wrongLabel !== WRONG_ATTEMPT_REACHED,
            })}
          >
            {NEED_HELP}
          </span>
        </>
      </div>
    </div>
  );
};

export default ParentalControlPIN;
