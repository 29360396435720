/**
 * The client side entry where the app is being rendered
 * to the selected DOM element.
 *
 * Allowing for hot module replacement in DEVELOPMENT mode.
 */

import ReactDOM from 'react-dom';
import { renderRoutes } from 'react-router-config';

import historyConfig from '#/config/history';
import App from '#/components/App/App';
import { getStore } from '#/redux/store';
import { getClientServices } from '#/services';
import { cleanAccedoOneSessionInClient } from '#/services/accedoOne/accedoOne';

const { appHistory, Router } = historyConfig;

// require('./addWhyDidYouRender').default(); // NOSONAR

cleanAccedoOneSessionInClient();

// Get hold of the redux store and history manager
const { store } = getStore({
  historyType: appHistory,
  services: getClientServices(),
});

export type AppDispatch = typeof store.dispatch;

// The app render function which will mount the application's
// component tree to the DOM node with id 'root'.
const render = () => {
  // Need to require these on the fly for HMR to work.
  const routes = require('#/config/router').default;

  const mountNode = document.getElementById('root');
  const router = <Router>{renderRoutes(routes)}</Router>;
  const method = window?.__ssr ? 'hydrate' : 'render';

  ReactDOM[method](<App store={store} content={router} />, mountNode);
};

// Render the component tree to the DOM
// unless it is a test run.
if (__TEST__) {
  module.exports = render;
} else {
  render();
}
