import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Page } from '@accedo/vdkweb-tv-ui';

import { PROMOTIONAL_PAGE } from '#/utils/navigationMap';
import useOrchestra from '#/services/orchestra/api';
import { getPageEntry, getEntriesById } from '#/utils/getAccedoOneData';
import { getVerticalNav } from '#/utils/navigationHelper';
import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { getContainerComponent } from '#/utils/containerUtils';
import { useUpdateScroll, useAppSelector } from '#/hooks';
import Spinner from '#/components/Spinner/Spinner';
import Scroll from '#/components/Scroll/Scroll';
import { getIsLowEndDevice } from '#/redux/modules/system';
import LeftGradient from '#/static/images/gradients/dark-gradient-left.png';
import VerticalGradient from '#/static/images/gradients/dark-gradient-vertical.png';
import { useNativeFocus } from '#/utils/accessibility';

import styles from './promotional.scss';

const { PAGE, PROMOTIONAL_CONTAINERS } = PROMOTIONAL_PAGE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: PROMOTIONAL_CONTAINERS,
    useLastFocus: true,
  },
  PROMOTIONAL_CONTAINERS: {
    id: PROMOTIONAL_CONTAINERS,
    parent: PAGE,
    forwardFocus: '',
  },
};

type Params = {
  pageId: string;
};

export const populateContainers = (
  containers: Container[],
  orchestra: any,
): Promise<Container[]> => {
  const containerDataPromises = containers.map(container => {
    if (container?.items && container?.items.length > 0) {
      return getEntriesById(container?.items);
    }

    if (container?.playlistUrl) {
      return orchestra?.fetchPlaylist(container?.playlistUrl);
    }

    return [];
  });

  return Promise.all(containerDataPromises).then((containerData: any[]) => {
    if (containerData.length > 0) {
      const errors = containerData.filter(data => data?.hasError).length;

      if (errors === containerData.length) {
        throw new Error('no data');
      }
    }

    const populatedContainers = containers.map(
      (container: Container, index: number) => {
        return {
          ...container,
          data: containerData[index],
        };
      },
    );

    return populatedContainers;
  });
};

const getContainerNav = (containerList: any[]) => {
  const containerNavIds = getVerticalNav(
    containerList.map(container => `${PAGE}-${container.id}`),
    {
      parent: PROMOTIONAL_CONTAINERS,
    },
  );

  navMap.PROMOTIONAL_CONTAINERS.forwardFocus = `${PAGE}-${containerList[0].id}`;

  return {
    containerNavIds,
  };
};

const getItemData = (data: any) => data;

const Promotional = () => {
  const [pageData, setPageData] = useState<any>();
  const { pageId } = useParams<Params>();
  const orchestra = useOrchestra();
  const updateScroll = useUpdateScroll(PAGE);
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  const getPageData = useCallback(async () => {
    getPageEntry(pageId)?.then(pageResult => {
      if (pageResult && pageResult?.containers) {
        populateContainers(pageResult?.containers, orchestra).then(
          (populatedContainers: Container[]) => {
            setPageData({
              ...pageResult,
              containers: populatedContainers,
            });
          },
        );
      }
    });
  }, [pageId]);

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    if (pageData) {
      useNativeFocus();
    }
  }, [pageData]);

  if (!pageData) {
    return <Spinner force />;
  }

  const { containerNavIds } = getContainerNav(pageData?.containers);

  return (
    <Page nav={navMap.PAGE} className={styles.wrapper}>
      <Scroll id={PAGE} extraPush={250}>
        {pageData?.logoImageUrl && (
          <>
            <span className={styles.promoMessage}>brought to you by</span>
            <img
              src={pageData?.logoImageUrl}
              className={styles.promoLogo}
              alt="Logo"
            />
          </>
        )}

        <div
          className={classNames(styles.backgroundImage, {
            [styles.animation]: !isLowEndDevice,
          })}
          style={{
            backgroundImage: ` url('${pageData?.backgroundImage}')`,
          }}
        >
          <div className={styles.title}>{pageData?.displayText}</div>

          <div className={styles.description}>{pageData?.description}</div>

          <img
            src={LeftGradient}
            alt="Horizontal Gradient"
            className={styles.gradient}
          />

          <img
            src={VerticalGradient}
            alt="Vertical Gradient"
            className={styles.gradient}
          />
        </div>

        <FocusDiv
          nav={navMap.PROMOTIONAL_CONTAINERS}
          className={styles.containers}
        >
          {pageData?.containers.map((container: any) => {
            return (
              <div key={container?.id}>
                {getContainerComponent(
                  container,
                  containerNavIds[`${PAGE}-${container.id}`],
                  getItemData,
                  { onFocus: updateScroll },
                )}
              </div>
            );
          })}
        </FocusDiv>
      </Scroll>
    </Page>
  );
};

export default Promotional;
