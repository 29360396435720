/* eslint-disable no-restricted-globals */
import { useState, useEffect, useCallback, useRef } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';
import { pageRedux, setPageDefaultActions } from '@accedo/vdkweb-tv-ui';
import { environment, device } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';

import { setupAppColors, setupAppFonts } from '#/utils/accedoControlParsing';
import { useNativeFocus } from '#/utils/accessibility';
import { historyService } from '#/services/historyService/historyService';
import Spinner from '#/components/Spinner/Spinner';
import {
  useAppDispatch,
  useVizioLibrary,
  useAccedoOneConfig,
  useAppSelector,
} from '#/hooks';
import redux from '#/redux/modules';

import appStyles from './app.scss';
import fetchFeed from '#/services/Feed/feedApi';

type Props = {
  children: React.ReactNode;
};

const { pageBack, pageExit } = pageRedux.actions;

const {
  nav: { getIsBackPrevented },
  system: { setIsLowEndDevice },
  movies: { setMoviesReducer },
  series: { setSeriesReducer },
} = redux;

const KEY_EVENT_PREFIX = 'device:vkey:';

const AppContent = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [appReady, setAppReady] = useState(false);
  const [appConfigLoaded, appConfig] = useAccedoOneConfig();
  const isBackPrevented = useAppSelector(getIsBackPrevented);
  const isBackPreventedRef = useRef<boolean>(isBackPrevented);

  isBackPreventedRef.current = isBackPrevented;

  // Load Vizio Companion API and listeners
  useVizioLibrary();

  useEffect(() => {
    if (appConfigLoaded && appConfig) {
      const { styles, assetsResolved } = appConfig;

      setupAppColors(styles);
      setupAppFonts(assetsResolved);
    }
  }, [appConfigLoaded, appConfig]);

  const onLoad = useCallback(() => {
    setAppReady(true);
  }, []);

  useEffect(() => {
    const fetchMovies = fetchFeed('movies');

    fetchMovies.then(result => dispatch(setMoviesReducer(result)));

    const fetchSeries = fetchFeed('series');

    fetchSeries.then(result => dispatch(setSeriesReducer(result)));
  }, []);

  const onKeyDown = useCallback(({ id }) => {
    const { UP, DOWN, LEFT, RIGHT, OK, BACK, EXIT } = vKey;

    switch (id) {
      case UP.id:
      case DOWN.id:
      case LEFT.id:
      case RIGHT.id: {
        focusManager.navigateFocus(
          `next${id.substring(KEY_EVENT_PREFIX.length)}`,
        );

        useNativeFocus();
        break;
      }

      case OK.id:
        focusManager.click();
        break;

      case BACK.id: {
        if (isBackPreventedRef.current) {
          return;
        }

        dispatch(pageBack());
        break;
      }

      case EXIT.id:
        dispatch(pageExit());
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    focusManager.setPersistTrail(true);
    historyService.init();

    setPageDefaultActions({
      backAction: () => {
        historyService.goBack();
      },
      exitAction: () => {
        // As system.exit would trigger XDK Exception in case it is not implemented for the platform,
        // we will catch this to avoid aborting unexpectedly
        try {
          device.system.exit();
        } catch (e) {
          console.warn(e);
        }
      },
    });

    environment.addEventListener(environment.DEVICE.ONLOAD, onLoad);
    environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);

    return () => {
      environment.removeEventListener(environment.DEVICE.ONLOAD, onLoad);
      environment.removeEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);
    };
  }, []);

  useEffect(() => {
    // Detect low end Vizio TVs, usually D series
    dispatch(
      setIsLowEndDevice(
        navigator.userAgent.toLowerCase().includes('mtka') ||
          navigator.userAgent.toLowerCase().includes('mtke'),
      ),
    );
  }, []);

  const appLoaded = !!appReady && appConfigLoaded;

  if (!appLoaded) {
    return <Spinner force />;
  }

  return (
    <>
      {appReady && (
        <div className={appStyles.root}>
          {appConfigLoaded && <>{children}</>}
        </div>
      )}
    </>
  );
};

export default AppContent;
