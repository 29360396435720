import LeftArrow from '#/static/images/icons/keyboard/arrow-left.svg';
import RightArrow from '#/static/images/icons/keyboard/arrow-right.svg';
import UppercaseArrow from '#/static/images/icons/keyboard/arrow-up.svg';
import SpaceBar from '#/static/images/icons/keyboard/space_bar.svg';
import Backspace from '#/static/images/icons/keyboard/backspace.svg';

import { Key, createHorizontalKeyNav } from './keyboardUtils';

const ALPHABET: Key[] = [
  {
    text: 'a',
    value: 'a',
    altText: 'A',
    altValue: 'A',
    accessibilityLabel: 'a',
    nav: {
      id: 'a',
      nextdown: 'LEFT',
      nextleft: 'z',
    },
    row: 1,
  },
  {
    text: 'b',
    value: 'b',
    altText: 'B',
    altValue: 'B',
    accessibilityLabel: 'b',
    nav: {
      id: 'b',
      nextdown: 'LEFT',
    },
    row: 1,
  },
  {
    text: 'c',
    value: 'c',
    altText: 'C',
    altValue: 'C',
    accessibilityLabel: 'c',
    nav: {
      id: 'c',
      nextdown: 'RIGHT',
    },
    row: 1,
  },
  {
    text: 'd',
    value: 'd',
    altText: 'D',
    altValue: 'D',
    accessibilityLabel: 'd',
    nav: {
      id: 'd',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'e',
    value: 'e',
    altText: 'E',
    altValue: 'E',
    accessibilityLabel: 'e',
    nav: {
      id: 'e',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'f',
    value: 'f',
    altText: 'F',
    altValue: 'F',
    accessibilityLabel: 'f',
    nav: {
      id: 'f',
      nextdown: 'UPPERCASE',
    },
    row: 1,
  },
  {
    text: 'g',
    value: 'g',
    altText: 'G',
    altValue: 'G',
    accessibilityLabel: 'g',
    nav: {
      id: 'g',
      nextdown: 'UPPERCASE',
    },
    row: 1,
  },
  {
    text: 'h',
    value: 'h',
    altText: 'H',
    altValue: 'H',
    accessibilityLabel: 'h',
    nav: {
      id: 'h',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'i',
    value: 'i',
    altText: 'I',
    altValue: 'I',
    accessibilityLabel: 'i',
    nav: {
      id: 'i',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'j',
    value: 'j',
    altText: 'J',
    altValue: 'J',
    accessibilityLabel: 'j',
    nav: {
      id: 'j',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'k',
    value: 'k',
    altText: 'K',
    altValue: 'K',
    accessibilityLabel: 'k',
    nav: {
      id: 'k',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'l',
    value: 'l',
    altText: 'L',
    altValue: 'L',
    accessibilityLabel: 'l',
    nav: {
      id: 'l',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'm',
    value: 'm',
    altText: 'M',
    altValue: 'M',
    accessibilityLabel: 'm',
    nav: {
      id: 'm',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'n',
    value: 'n',
    altText: 'N',
    altValue: 'N',
    accessibilityLabel: 'n',
    nav: {
      id: 'n',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'o',
    value: 'o',
    altText: 'O',
    altValue: 'O',
    accessibilityLabel: 'o',
    nav: {
      id: 'o',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'p',
    value: 'p',
    altText: 'P',
    altValue: 'P',
    accessibilityLabel: 'p',
    nav: {
      id: 'p',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'q',
    value: 'q',
    altText: 'Q',
    altValue: 'Q',
    accessibilityLabel: 'q',
    nav: {
      id: 'q',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'r',
    value: 'r',
    altText: 'R',
    altValue: 'R',
    accessibilityLabel: 'r',
    nav: {
      id: 'r',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 's',
    value: 's',
    altText: 'S',
    altValue: 'S',
    accessibilityLabel: 's',
    nav: {
      id: 's',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 't',
    value: 't',
    altText: 'T',
    altValue: 'T',
    accessibilityLabel: 't',
    nav: {
      id: 't',
      nextdown: 'SPACE',
    },
    row: 1,
  },
  {
    text: 'u',
    value: 'u',
    altText: 'U',
    altValue: 'U',
    accessibilityLabel: 'u',
    nav: {
      id: 'u',
      nextdown: 'NUMBERS',
    },
    row: 1,
  },
  {
    text: 'v',
    value: 'v',
    altText: 'V',
    altValue: 'V',
    accessibilityLabel: 'v',
    nav: {
      id: 'v',
      nextdown: 'OTHER_CHARS',
    },
    row: 1,
  },
  {
    text: 'w',
    value: 'w',
    altText: 'W',
    altValue: 'W',
    accessibilityLabel: 'w',
    nav: {
      id: 'w',
      nextdown: 'OTHER_CHARS',
    },
    row: 1,
  },
  {
    text: 'x',
    value: 'x',
    altText: 'X',
    altValue: 'X',
    accessibilityLabel: 'x',
    nav: {
      id: 'x',
      nextdown: 'OTHER_CHARS',
    },
    row: 1,
  },
  {
    text: 'y',
    value: 'y',
    altText: 'Y',
    altValue: 'Y',
    accessibilityLabel: 'y',
    nav: {
      id: 'y',
      nextdown: 'DELETE',
    },
    row: 1,
  },
  {
    text: 'z',
    value: 'z',
    altText: 'Z',
    altValue: 'Z',
    accessibilityLabel: 'z',
    nav: {
      id: 'z',
      nextdown: 'DELETE',
      nextright: 'a',
    },
    row: 1,
  },
];

const SECOND_ROW: Key[] = [
  {
    text: '<',
    icon: {
      src: LeftArrow,
    },
    value: 'LEFT',
    special: true,
    accessibilityLabel: 'Move cursor back',
    nav: {
      id: 'LEFT',
      nextup: 'a',
      nextleft: 'DELETE',
    },
    numbersNav: {
      nextup: '0',
    },
    specialNav: {
      nextup: '.',
    },
    otherCharsNav: {
      nextup: 'á',
    },
    row: 2,
    customSize: 1.5,
  },
  {
    text: '>',
    icon: {
      src: RightArrow,
    },
    value: 'RIGHT',
    special: true,
    accessibilityLabel: 'Move cursor forward',
    nav: {
      id: 'RIGHT',
      nextup: 'c',
    },
    numbersNav: {
      nextup: '0',
    },
    specialNav: {
      nextup: '_',
    },
    otherCharsNav: {
      nextup: 'í',
    },
    row: 2,
    customSize: 1.5,
  },
  {
    text: '@ & #',
    value: 'SPECIAL_CHARS',
    special: true,
    accessibilityLabel: 'Toggle special characters',
    nav: {
      id: 'SPECIAL_CHARS',
      nextup: 'd',
    },
    numbersNav: {
      nextup: '1',
    },
    specialNav: {
      nextup: '!',
    },
    otherCharsNav: {
      nextup: 'ó',
    },
    row: 2,
    customSize: 2,
  },
  {
    text: '^',
    icon: {
      src: UppercaseArrow,
    },
    value: 'UPPERCASE',
    special: true,
    accessibilityLabel: 'Toggle uppercase',
    nav: {
      id: 'UPPERCASE',
      nextup: 'f',
    },
    numbersNav: {
      nextup: '2',
    },
    specialNav: {
      nextup: '#',
    },
    otherCharsNav: {
      nextup: 'â',
    },
    row: 2,
    customSize: 1.5,
  },
  {
    text: 'Space',
    icon: {
      src: SpaceBar,
    },
    value: 'SPACE',
    special: true,
    accessibilityLabel: 'Space',
    nav: {
      id: 'SPACE',
      nextup: 'm',
    },
    numbersNav: {
      nextup: '4',
    },
    specialNav: {
      nextup: '+',
    },
    otherCharsNav: {
      nextup: 'ï',
    },
    row: 2,
    customSize: 13,
  },
  {
    text: '1 2 3',
    value: 'NUMBERS',
    special: true,
    accessibilityLabel: 'Toggle numerical characters',
    nav: {
      id: 'NUMBERS',
      nextup: 'u',
    },
    numbersNav: {
      nextup: '8',
    },
    specialNav: {
      nextup: ',',
    },
    otherCharsNav: {
      nextup: 'å',
    },
    row: 2,
    customSize: 1.5,
  },
  {
    text: 'å é î ø ü',
    value: 'OTHER_CHARS',
    special: true,
    accessibilityLabel: 'Toggle latin characters',
    nav: {
      id: 'OTHER_CHARS',
      nextup: 'w',
    },
    numbersNav: {
      nextup: '8',
    },
    specialNav: {
      nextup: ';',
    },
    otherCharsNav: {
      nextup: 'ç',
    },
    row: 2,
    customSize: 3.5,
  },
  {
    text: 'Delete',
    value: 'DELETE',
    icon: {
      src: Backspace,
    },
    special: true,
    accessibilityLabel: 'Delete',
    nav: {
      id: 'DELETE',
      nextup: 'z',
      nextright: 'LEFT',
    },
    numbersNav: {
      nextup: '9',
    },
    specialNav: {
      nextup: '\\',
    },
    otherCharsNav: {
      nextup: '¿',
    },
    row: 2,
    customSize: 1.5,
  },
];

const NUMBERS: Key[] = [
  {
    text: '0',
    value: '0',
    accessibilityLabel: '0',
    nav: {
      id: '0',
      nextdown: 'LEFT',
      nextleft: '9',
    },
    row: 3,
  },
  {
    text: '1',
    value: '1',
    accessibilityLabel: '1',
    nav: {
      id: '1',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 3,
  },
  {
    text: '2',
    value: '2',
    accessibilityLabel: '2',
    nav: {
      id: '2',
      nextdown: 'UPPERCASE',
    },
    row: 3,
  },
  {
    text: '3',
    value: '3',
    accessibilityLabel: '3',
    nav: {
      id: '3',
      nextdown: 'SPACE',
    },
    row: 3,
  },
  {
    text: '4',
    value: '4',
    accessibilityLabel: '4',
    nav: {
      id: '4',
      nextdown: 'SPACE',
    },
    row: 3,
  },
  {
    text: '5',
    value: '5',
    accessibilityLabel: '5',
    nav: {
      id: '5',
      nextdown: 'SPACE',
    },
    row: 3,
  },
  {
    text: '6',
    value: '6',
    accessibilityLabel: '6',
    nav: {
      id: '6',
      nextdown: 'SPACE',
    },
    row: 3,
  },
  {
    text: '7',
    value: '7',
    accessibilityLabel: '7',
    nav: {
      id: '7',
      nextdown: 'SPACE',
    },
    row: 3,
  },
  {
    text: '8',
    value: '8',
    accessibilityLabel: '8',
    nav: {
      id: '8',
      nextdown: 'OTHER_CHARS',
    },
    row: 3,
    customSize: 3,
  },
  {
    text: '9',
    value: '9',
    accessibilityLabel: '9',
    nav: {
      id: '9',
      nextdown: 'DELETE',
      nextright: '0',
    },
    row: 3,
    customSize: 3,
  },
];

const SPECIAL_KEYS: Key[] = [
  {
    text: '.',
    value: '.',
    accessibilityLabel: '.',
    nav: {
      id: '.',
      nextdown: 'LEFT',
      nextleft: '\\',
    },
    row: 4,
  },
  {
    text: '-',
    value: '-',
    accessibilityLabel: '-',
    nav: {
      id: '-',
      nextdown: 'LEFT',
    },
    row: 4,
  },
  {
    text: '_',
    value: '_',
    accessibilityLabel: '_',
    nav: {
      id: '_',
      nextdown: 'RIGHT',
    },
    row: 4,
  },
  {
    text: '!',
    value: '!',
    accessibilityLabel: '!',
    nav: {
      id: '!',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '@',
    value: '@',
    accessibilityLabel: '@',
    nav: {
      id: '@',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '#',
    value: '#',
    accessibilityLabel: '#',
    nav: {
      id: '#',
      nextdown: 'UPPERCASE',
    },
    row: 4,
  },
  {
    text: '$',
    value: '$',
    accessibilityLabel: '$',
    nav: {
      id: '$',
      nextdown: 'UPPERCASE',
    },
    row: 4,
  },
  {
    text: '%',
    value: '%',
    accessibilityLabel: '%',
    nav: {
      id: '%',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '^',
    value: '^',
    accessibilityLabel: '^',
    nav: {
      id: '^',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '&',
    value: '&',
    accessibilityLabel: '&',
    nav: {
      id: '&',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '*',
    value: '*',
    accessibilityLabel: '*',
    nav: {
      id: '*',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: "'",
    value: "'",
    accessibilityLabel: "'",
    nav: {
      id: "'",
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '+',
    value: '+',
    accessibilityLabel: '+',
    nav: {
      id: '+',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '/',
    value: '/',
    accessibilityLabel: '/',
    nav: {
      id: '/',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '=',
    value: '=',
    accessibilityLabel: '=',
    nav: {
      id: '=',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '?',
    value: '?',
    accessibilityLabel: '?',
    nav: {
      id: '?',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '~',
    value: '~',
    accessibilityLabel: '~',
    nav: {
      id: '~',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '"',
    value: '"',
    accessibilityLabel: '"',
    nav: {
      id: '"',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: '(',
    value: '(',
    accessibilityLabel: '(',
    nav: {
      id: '(',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: ')',
    value: ')',
    accessibilityLabel: ')',
    nav: {
      id: ')',
      nextdown: 'SPACE',
    },
    row: 4,
  },
  {
    text: ',',
    value: ',',
    accessibilityLabel: ',',
    nav: {
      id: ',',
      nextdown: 'NUMBERS',
    },
    row: 4,
  },
  {
    text: ':',
    value: ':',
    accessibilityLabel: ':',
    nav: {
      id: ':',
      nextdown: 'OTHER_CHARS',
    },
    row: 4,
  },
  {
    text: ';',
    value: ';',
    accessibilityLabel: ';',
    nav: {
      id: ';',
      nextdown: 'OTHER_CHARS',
    },
    row: 4,
  },
  {
    text: '<',
    value: '<',
    accessibilityLabel: ';',
    nav: {
      id: '<',
      nextdown: 'OTHER_CHARS',
    },
    row: 4,
  },
  {
    text: '>',
    value: '>',
    accessibilityLabel: '>',
    nav: {
      id: '>',
      nextdown: 'DELETE',
    },
    row: 4,
  },
  {
    text: '\\',
    value: '\\',
    accessibilityLabel: '\\',
    nav: {
      id: '\\',
      nextdown: 'DELETE',
      nextright: '.',
    },
    row: 4,
  },
];

const OTHER_CHARS: Key[] = [
  {
    text: 'á',
    value: 'á',
    accessibilityLabel: 'á',
    nav: {
      id: 'á',
      nextdown: 'LEFT',
      nextleft: '¿',
    },
    row: 5,
  },
  {
    text: 'é',
    value: 'é',
    accessibilityLabel: 'é',
    nav: {
      id: 'é',
      nextdown: 'LEFT',
    },
    row: 5,
  },
  {
    text: 'í',
    value: 'í',
    accessibilityLabel: 'í',
    nav: {
      id: 'í',
      nextdown: 'RIGHT',
    },
    row: 5,
  },
  {
    text: 'ó',
    value: 'ó',
    accessibilityLabel: 'ó',
    nav: {
      id: 'ó',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 5,
  },
  {
    text: 'ú',
    value: 'ú',
    accessibilityLabel: 'ú',
    nav: {
      id: 'ú',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 5,
  },
  {
    text: 'â',
    value: 'â',
    accessibilityLabel: 'â',
    nav: {
      id: 'â',
      nextdown: 'UPPERCASE',
    },
    row: 5,
  },
  {
    text: 'ê',
    value: 'ê',
    accessibilityLabel: 'ê',
    nav: {
      id: 'ê',
      nextdown: 'UPPERCASE',
    },
    row: 5,
  },
  {
    text: 'î',
    value: 'î',
    accessibilityLabel: 'î',
    nav: {
      id: 'î',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ô',
    value: 'ô',
    accessibilityLabel: 'ô',
    nav: {
      id: 'ô',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'û',
    value: 'û',
    accessibilityLabel: 'û',
    nav: {
      id: 'û',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ä',
    value: 'ä',
    accessibilityLabel: 'ä',
    nav: {
      id: 'ä',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ë',
    value: 'ë',
    accessibilityLabel: 'ë',
    nav: {
      id: 'ë',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ï',
    value: 'ï',
    accessibilityLabel: 'ï',
    nav: {
      id: 'ï',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ö',
    value: 'ö',
    accessibilityLabel: 'ö',
    nav: {
      id: 'ö',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ü',
    value: 'ü',
    accessibilityLabel: 'ü',
    nav: {
      id: 'ü',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'à',
    value: 'à',
    accessibilityLabel: 'à',
    nav: {
      id: 'à',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'è',
    value: 'è',
    accessibilityLabel: 'è',
    nav: {
      id: 'è',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ì',
    value: 'ì',
    accessibilityLabel: 'ì',
    nav: {
      id: 'ì',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ò',
    value: 'ò',
    accessibilityLabel: 'ò',
    nav: {
      id: 'ò',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'ù',
    value: 'ù',
    accessibilityLabel: 'ù',
    nav: {
      id: 'ù',
      nextdown: 'SPACE',
    },
    row: 5,
  },
  {
    text: 'å',
    value: 'å',
    accessibilityLabel: 'å',
    nav: {
      id: 'å',
      nextdown: 'NUMBERS',
    },
    row: 5,
  },
  {
    text: 'ñ',
    value: 'ñ',
    accessibilityLabel: 'ñ',
    nav: {
      id: 'ñ',
      nextdown: 'OTHER_CHARS',
    },
    row: 5,
  },
  {
    text: 'ç',
    value: 'ç',
    accessibilityLabel: 'ç',
    nav: {
      id: 'ç',
      nextdown: 'OTHER_CHARS',
    },
    row: 5,
  },
  {
    text: 'ý',
    value: 'ý',
    accessibilityLabel: 'ý',
    nav: {
      id: 'ý',
      nextdown: 'OTHER_CHARS',
    },
    row: 5,
  },
  {
    text: 'ø',
    value: 'ø',
    accessibilityLabel: 'ø',
    nav: {
      id: 'ø',
      nextdown: 'DELETE',
    },
    row: 5,
  },
  {
    text: '¿',
    value: '¿',
    accessibilityLabel: '¿',
    nav: {
      id: '¿',
      nextdown: 'DELETE',
      nextright: 'á',
    },
    row: 5,
  },
];

export const KEYS: Key[] = [
  ...createHorizontalKeyNav(ALPHABET),
  ...createHorizontalKeyNav(SECOND_ROW),
  ...createHorizontalKeyNav(NUMBERS),
  ...createHorizontalKeyNav(SPECIAL_KEYS),
  ...createHorizontalKeyNav(OTHER_CHARS),
];
