import getResolution from './getResolution';

const HD = 1280;

export const getRelativePixel = (pixels: number): number => {
  if (!pixels) {
    return 0;
  }

  const { width } = getResolution();
  const fullHDContext = HD * 0.01;
  const currentContext = width * 0.01;
  const _vw = pixels / fullHDContext;

  return Math.round(_vw * currentContext);
};

const getPosition = (pixels: number, context: number) => {
  const vwContext = context * 0.01;

  return pixels / vwContext;
};

export const vw = (pixels: number) => {
  return `${getPosition(pixels, 1280)}vw`;
};

export const vh = (pixels: number) => {
  return `${getPosition(pixels, 720)}vh`;
};
