import { useState, useCallback } from 'react';

import FocusDiv from '#/components/FocusDiv/FocusDiv';

type props = {
  nav: XDKNav;
  data: any;
  component: React.ComponentType<any>;
  classNameItemWrapper: string;
  onItemFocus: any;
};

const GridItem = ({
  nav,
  data,
  component: Component,
  classNameItemWrapper,
  onItemFocus,
}: props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [clicked, setClicked] = useState(0);

  const onClick = useCallback(() => {
    setClicked(clicked + 1);
  }, [clicked]);

  const itemFinalData = {
    ...data,
    ...(data?.movieGenres ? { isMovie: true } : { isShow: true }),
  };

  return (
    <FocusDiv
      nav={nav}
      className={classNameItemWrapper}
      onFocus={() => {
        onItemFocus();
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onClick={onClick}
    >
      <Component data={itemFinalData} clicked={clicked} isFocused={isFocused} />
    </FocusDiv>
  );
};

export default GridItem;
