import { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';

import { focusManager } from '@accedo/vdkweb-navigation';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import Swimlane from '#/components/Swimlane/Swimlane';
import Button from '#/components/Button/Button';
import { EPISODE, EpisodeTile } from '#/components/Tiles';
import { useAppSelector } from '#/hooks';
import { getIsLowEndDevice } from '#/redux/modules/system';
import { getHorizontalNav } from '#/utils/navigationHelper';

import styles from './seasonPicker.scss';

const PICKER = 'PICKER';
const SEASON = 'SEASON';
const SEASONS_SWIMLANE = 'SEASONS_SWIMLANE';

const navMap = {
  PICKER: {
    id: PICKER,
    useLastFocus: true,
  },
  SEASONS_SWIMLANE: {
    id: SEASONS_SWIMLANE,
    nextup: PICKER,
  },
};

type Props = {
  nav: XDKNav;
  onFocus: (id: string) => void;
  seasons: any;
};

const getNewAxis = (buttonId: string) => {
  if (!buttonId) {
    return 0;
  }

  const buttonElement = document.getElementById(buttonId);

  return -(buttonElement?.offsetLeft || 0);
};

const getSeasonsNav = (seasons: any[]) => {
  const seasonsNavIds = getHorizontalNav(
    seasons.map(item => `${SEASON}_${item?.id}`),
    {
      parent: PICKER,
    },
  );

  return {
    seasonsNavIds,
  };
};

const SeasonPicker = ({ nav, onFocus, seasons }: Props) => {
  const [axis, setNewAxis] = useState(0);
  const [activeSeason, setActiveSeason] = useState<any>(seasons[0]);
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  const focusContainer = useCallback(() => {
    const currentId = focusManager.getCurrentFocus();

    setNewAxis(getNewAxis(currentId));
    onFocus(nav.id);
  }, []);

  const getSeasonData = useCallback(async (id: number) => {
    const season: any = seasons.find((s: any) => s.id === id);

    setActiveSeason(season);
  }, []);

  useEffect(() => {
    getSeasonData(activeSeason?.id);
  }, []);

  const { seasonsNavIds } = getSeasonsNav(seasons);

  return (
    <FocusDiv nav={{ ...nav, forwardFocus: PICKER }}>
      <FocusDiv
        nav={{
          ...navMap.PICKER,
          parent: nav.id,
          forwardFocus: `${SEASON}_${seasons[0]?.id}`,
          nextdown: SEASONS_SWIMLANE,
        }}
        className={styles.picker}
      >
        <div
          className={classNames({
            [styles.scroll]: !isLowEndDevice,
          })}
          style={{
            transform: `translateX(${axis}px)`,
          }}
        >
          {seasons?.map((item: any, i: number) => (
            <Button
              className={classNames(styles.season, {
                [styles.activeSeason]: activeSeason?.id === item?.id,
              })}
              classNameFocused={styles.focusedSeason}
              nav={seasonsNavIds[`${SEASON}_${item?.id}`]}
              key={i}
              onFocus={focusContainer}
              onClick={() => getSeasonData(item?.id)}
              accessibilityLabel={`Season ${item?.number}`}
            >
              {`Season ${item?.number}`}
            </Button>
          ))}
        </div>
      </FocusDiv>

      {activeSeason?.tvEpisodes && (
        <Swimlane
          key={activeSeason?.id}
          nav={{
            ...navMap.SEASONS_SWIMLANE,
            parent: nav.id,
          }}
          onFocus={onFocus}
          data={activeSeason?.tvEpisodes}
          className={styles.swimlaneContainer}
          component={EpisodeTile}
          itemType={EPISODE}
        />
      )}
    </FocusDiv>
  );
};

export default SeasonPicker;
