import { startUsage, stopUsage } from '#/services/analytics/analytics';

import { APP_START, APP_QUIT } from '../modules/lifecycle';

const analytics = () => (next: any) => (action: any) => {
  if (action) {
    if (action.type === APP_START) {
      startUsage();
    } else if (action.type === APP_QUIT) {
      stopUsage();
    }
  }

  return next(action);
};

export default analytics;
