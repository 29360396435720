import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppServices } from '@accedo/accedo-one';

import type { RootState } from '#/redux/reducer';

type ThunkApi = {
  extra: {
    accedoOne: AppServices | null;
  };
};

type StatusResponse = {
  status?: string;
};

type StatusState = {
  error?: object;
  status?: string;
  hasError: boolean;
  isFetching: boolean;
};

const name = 'status';
const initialState: StatusState = {
  isFetching: false,
  hasError: false,
};

let rootSelector: RootSelector<StatusState> = (state: any) => state[name];

export const fetchStatus = createAsyncThunk<StatusResponse, void, ThunkApi>(
  'fetchStatus',
  (_, { extra }) => {
    const { accedoOne } = extra;

    if (!accedoOne) {
      return {};
    }

    return accedoOne.status.getAppStatus();
  },
  {
    condition: (_, { getState }) => {
      const state = rootSelector(getState());

      if (state.isFetching) {
        return false;
      }

      return true;
    },
  },
);

export const { reducer } = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatus.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      fetchStatus.rejected,
      (state, action: PayloadAction<any>) => {
        const { error } = action.payload;

        state.isFetching = false;
        state.hasError = true;
        state.error = error;
      },
    );

    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      if (state.isFetching) {
        state.isFetching = false;
      }

      state.status = action.payload.status;
    });
  },
});

export const setRootSelector: SetRootSelector<StatusState> = selector => {
  rootSelector = selector;
};

export const getStatus = (state: RootState) => rootSelector(state).status;
export const getIsFetching = (state: RootState) =>
  rootSelector(state).isFetching;
