import { memo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { getIsLowEndDevice } from '#/redux/modules/system';
import { useUpdateEffect, useAppSelector } from '#/hooks';

import styles from './tiles.scss';

const OverflowTile = ({
  isFocused,
  clicked,
  type,
  title,
  data,
}: SwimlaneItemProps<TypeTile>) => {
  const history = useHistory();
  const isLowEndDevice = useAppSelector(getIsLowEndDevice);

  useUpdateEffect(() => {
    const playlistUrl = data?.playlistUrl;
    const pieces = playlistUrl.split('/');
    const playlistId = pieces[pieces.length - 1];

    history.push(
      `/view-more/${title}/${type}/${
        data?.isShow ? 'show' : 'movie'
      }/${playlistId}`,
    );
  }, [clicked]);

  return (
    <div className={styles.wrapper}>
      <div aria-label="View More Like These Titles" />
      <div
        className={classNames(styles.baseTileContainer, {
          [styles.focused]: isFocused,
          [styles.animation]: !isLowEndDevice,
        })}
        aria-hidden="true"
      >
        <div className={styles.internalText}>View More</div>
      </div>
    </div>
  );
};

export default memo(OverflowTile);
