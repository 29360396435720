type StyleProps = {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
};

type AppClientRect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
};

/**
 * Retrieves the element's styles
 * @param ref - element to retrieve the padding from
 * @returns object containing padding-top and padding-bototm
 */
export const getStyles = (ref: HTMLElement): StyleProps => {
  const elementStyles = window.getComputedStyle(ref);
  const marginTop =
    parseInt(elementStyles.getPropertyValue('margin-top'), 10) || 0;
  const marginBottom =
    parseInt(elementStyles.getPropertyValue('margin-bottom'), 10) || 0;
  const paddingTop =
    parseInt(elementStyles.getPropertyValue('padding-top'), 10) || 0;
  const paddingBottom =
    parseInt(elementStyles.getPropertyValue('padding-bottom'), 10) || 0;
  const marginLeft =
    parseInt(elementStyles.getPropertyValue('margin-left'), 10) || 0;
  const marginRight =
    parseInt(elementStyles.getPropertyValue('margin-right'), 10) || 0;
  const paddingLeft =
    parseInt(elementStyles.getPropertyValue('padding-left'), 10) || 0;
  const paddingRight =
    parseInt(elementStyles.getPropertyValue('padding-right'), 10) || 0;

  return {
    marginTop,
    marginBottom,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    paddingLeft,
    paddingRight,
  };
};

/**
 * Retrieves the element's styles
 * @param ref - element to retrieve the padding from
 * @param withStyles - boolean to include element's styling
 * @returns object containing padding-top and padding-bototm
 */
export const getClientRect = (
  ref: HTMLElement,
  withStyles?: boolean,
): AppClientRect & Partial<StyleProps> => {
  const clientRect = ref.getBoundingClientRect();

  const rectObject: AppClientRect = {
    bottom: clientRect.bottom,
    height: clientRect.height,
    left: clientRect.left,
    right: clientRect.right,
    top: clientRect.top,
    width: clientRect.width,
  };

  if (withStyles) {
    return {
      ...rectObject,
      ...getStyles(ref),
    };
  }

  return rectObject;
};

export default getClientRect;
