/* These keys match with asset keys received from Accedo Control */
const fontsConfig: { [key: string]: { [key: string]: string } } = {
  lightFont: {
    'font-family': 'Light Font',
    'font-style': 'normal',
  },
  boldFont: {
    'font-family': 'Bold Font',
    'font-style': 'normal',
  },
};

const setupAppColors = (styleConfig: any) => {
  const { colors, fontFamilyPerItem } = styleConfig;

  const root = document.querySelector(':root') as HTMLElement;
  const computedStyles = getComputedStyle(root);

  Object.keys(colors).forEach((key: string) => {
    const propertyName = `--${key}`;

    // Do not accept random keys from Control, only ones that the app is expecting
    // Expected keys should be defined in _colors.scss
    if (computedStyles.getPropertyValue(propertyName)) {
      root.style.setProperty(`--${key}`, colors[key]);
    } else {
      console.warn(`Rejecting color style key: ${key}`);
    }
  });

  Object.keys(fontFamilyPerItem).forEach((key: string) => {
    const propertyName = `--${key}`;

    // Do not accept random keys from Control, only ones that the app is expecting
    // Expected keys should be defined in _colors.scss
    if (computedStyles.getPropertyValue(propertyName)) {
      root.style.setProperty(`--${key}`, fontFamilyPerItem[key]);
    } else {
      console.warn(`Rejecting font style key: ${key}`);
    }
  });
};

const setupAppFonts = (assets: any) => {
  const sheet = document.styleSheets[0];

  try {
    Object.keys(assets).forEach(key => {
      const fontConfig = fontsConfig[key];

      if (fontConfig) {
        sheet.insertRule(
          `@font-face {font-family: ${fontConfig['font-family']};font-style: ${fontConfig['font-style']};src:url('${assets[key]}');}`,
          sheet.cssRules.length,
        );
      }
    });
  } catch (e) {
    console.warn(`Can't read the css rules of: ${sheet}`, e);
  }
};

export { setupAppColors, setupAppFonts };
