const fetchFeed = async (type: string) => {
  try {
    const response = await fetch(
      'https://s3.amazonaws.com/accedo.nam.eng/projects/vizio-dp-poc/contentFeed.json',
    );
    const jsonData = await response.json();
    if (type === 'movies') {
      return jsonData.movies;
    } else {
      return jsonData.tvShows;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default fetchFeed;
