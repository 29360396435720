export const configUrl =
  'https://s3.amazonaws.com/accedo.nam.eng/projects/vizio-dp-poc/config-with-swimlanes.json';
export const contentFeedUrl =
  'https://s3.amazonaws.com/accedo.nam.eng/projects/vizio-dp-poc/contentFeed.json';

const fetchData = async (url: string) => {
  try {
    const response = await fetch(url);

    const data: any = await response.json();

    return data;
  } catch (error) {
    return { hasError: true, error };
  }
};

const useDirectPublisherConfig = () => {
  return {
    fetchMenuItems: async () => {
      const data = await fetchData(configUrl);

      return data?.globalMenuResolved?.itemsResolved;
    },
    fetchHomeContainers: async () => {
      const data = await fetchData(configUrl);

      return data?.landingPageResolved?.containersResolved;
    },
  };
};

export default useDirectPublisherConfig;
