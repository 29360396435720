import styles from './tiles.scss';

export const PORTRAIT = styles.portraitTile;
export const LANDSCAPE = styles.landscapeTile;
export const EPISODE = styles.episodeTile;
export const CATEGORY = styles.categoryTile;
export const OVERFLOW = styles.overflowTile;

export const PORTRAIT_TILE_GRID = styles.portraitTileGrid;
export const LANDSCAPE_TILE_GRID = styles.landscapeTileGrid;

export { default as PortraitTile } from './PortraitTile';
export { default as LandscapeTile } from './LandscapeTile';
export { default as EpisodeTile } from './EpisodeTile';
export { default as CategoryTile } from './CategoryTile';
export { default as ContinueWatchingTile } from './ContinueWatchingTile';
export { default as OverflowTile } from './OverflowTile';
