import { renderRoutes } from 'react-router-config';
import Spinner from '#/components/Spinner/Spinner';
import Sidebar from '#/components/Sidebar/Sidebar';

import styles from './viewContainer.scss';

type Props = {
  route: any;
};

const ViewContainer = ({ route }: Props) => {
  return (
    <div className={styles.viewport}>
      <div className={styles.wrapper}>
        <Sidebar />
        <div className={styles.content}>
          <Spinner />
          {renderRoutes(route.routes)}
        </div>
      </div>
    </div>
  );
};

export default ViewContainer;
