import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type AdSettings = {
  id: string;
  idType: string;
  limitTracking: boolean;
};

type SystemState = {
  deviceId: string;
  adSettings: AdSettings;
  ccEnabled: boolean;
  ttsEnabled: boolean;
  isLowEndDevice: boolean;
};

const name = 'system';

const initialState: SystemState = {
  deviceId: 'workstation-device-id',
  adSettings: {
    id: '',
    idType: '',
    limitTracking: false,
  },
  ccEnabled: false,
  ttsEnabled: false,
  isLowEndDevice: false,
};

export const {
  reducer,
  actions: {
    setDeviceId,
    setCCEnabled,
    setTTSEnabled,
    setAdSettings,
    setIsLowEndDevice,
  },
} = createSlice({
  name,
  initialState,
  reducers: {
    setIsLowEndDevice(
      state = initialState,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isLowEndDevice = payload;
    },
    setDeviceId(state = initialState, { payload }: PayloadAction<string>) {
      state.deviceId = payload;
    },
    setCCEnabled(state = initialState, { payload }: PayloadAction<boolean>) {
      state.ccEnabled = payload;
    },
    setTTSEnabled(state = initialState, { payload }: PayloadAction<boolean>) {
      state.ttsEnabled = payload;
    },
    setAdSettings(
      state = initialState,
      { payload }: PayloadAction<AdSettings>,
    ) {
      state.adSettings = payload;
    },
  },
});

let rootSelector: RootSelector<SystemState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<SystemState> = selector => {
  rootSelector = selector;
};

export const getIsLowEndDevice = (state: RootState) =>
  rootSelector(state).isLowEndDevice;
export const getDeviceId = (state: RootState) => rootSelector(state).deviceId;
export const getCCEnabled = (state: RootState) => rootSelector(state).ccEnabled;
export const getTTSEnabled = (state: RootState) =>
  rootSelector(state).ttsEnabled;
export const getAdSettings = (state: RootState) =>
  rootSelector(state).adSettings;
