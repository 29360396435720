import { memo, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import isEqual from 'react-fast-compare';

import { focusManager } from '@accedo/vdkweb-navigation';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { useLatest, useIsMounted } from '#/hooks';

import styles from './swimlane.scss';

type Props<ItemDataType> = SwimlaneItemWrapperProps<ItemDataType> & {
  inVisibleArea: boolean;
};

const SwimlaneItemWrapper = <ItemDataType,>({
  index,
  onItemFocus,
  inVisibleArea,
  className,
  component: Component,
  type,
  nav,
  swimlaneId,
  data,
  ...props
}: Props<ItemDataType>) => {
  const isMounted = useIsMounted();
  const [clicked, setClicked] = useState(0);
  const [isFocused, setIsFocused] = useState(
    focusManager.getCurrentFocus() === nav.id,
  );
  const lastClickedValueRef = useLatest(clicked);

  const onFocus = useCallback(() => {
    setIsFocused(true);
    onItemFocus(index);
  }, []);

  const onBlur = useCallback(() => {
    if (isMounted()) {
      setIsFocused(false);
    }
  }, []);

  const onClick = useCallback(() => {
    if (isMounted()) {
      setClicked(lastClickedValueRef.current + 1);
    }
  }, []);

  // Enter if an item initial render is focused
  useEffect(() => {
    if (isFocused) {
      onItemFocus?.(index);
    }
  }, []);

  return (
    <FocusDiv
      nav={nav}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      className={classNames('item', styles.itemWrapper, className)}
    >
      {inVisibleArea && (
        <Component
          isFocused={isFocused}
          navId={nav.id}
          clicked={clicked}
          swimlaneId={swimlaneId}
          index={index}
          type={type}
          data={data}
          {...props}
        />
      )}
    </FocusDiv>
  );
};

export default memo(SwimlaneItemWrapper, isEqual);
