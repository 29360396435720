import { useState, useCallback, useEffect } from 'react';

function useImage(src: string) {
  const [loaded, setLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!src) {
      return;
    }

    const image = new Image();

    image.src = src;
    image.addEventListener('load', onLoad, { once: true });

    return () => {
      image.removeEventListener('load', onLoad);
    };
  }, [src]);

  return loaded;
}

export default useImage;
