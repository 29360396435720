import { getAccedoOneClient } from '../accedoOne/accedoOne';

type EventFunctions = 'sendUsageStartEvent' | 'sendUsageStopEvent';

const call = (funcName: EventFunctions): Promise<unknown> | void => {
  const client = getAccedoOneClient();

  if (client) {
    return client[funcName]().catch((err: any) => {
      // If we fail to send the event to Accedo One
      // we'll catch it and return the error.
      return err;
    });
  }
};

export const startUsage = () => {
  return call('sendUsageStartEvent');
};

export const stopUsage = () => {
  return call('sendUsageStopEvent');
};
