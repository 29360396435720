import { focusManager } from '@accedo/vdkweb-navigation';

export const useNativeFocus = () => {
  const curFocusedEl = document.getElementById(focusManager.getCurrentFocus());

  curFocusedEl?.focus({ preventScroll: true });
};

export const useChromevox = (text: string) => {
  if (window?.VIZIO) {
    window?.VIZIO?.Chromevox?.play(text);
  }
};

export const composeCategoryTileLabel = (displayName: string) => {
  return `${displayName} category`;
};

export const composeBannerLabel = (displayText: string) => {
  return `${displayText} promotional banner`;
};

export const composeHeroLabel = (name: string) => {
  return `Featured content: ${name}, view details button`;
};

export const composeModalLabel = (
  title: string,
  subtitle: string,
  text: string,
  action: string,
) => {
  return `${title}, ${subtitle}, ${text}, ${action}`;
};

export const composeSidebarLabel = (displayText: string) => {
  return `Main menu: ${displayText}`;
};

// Depending on the metadata more strings can be added here
// keeping simple because of Orchestra missing fields
export const composeDetailsLabel = (name: string, isMovie: boolean) => {
  if (isMovie) {
    return `Play movie: ${name}`;
  }

  return `Play series: ${name}`;
};
