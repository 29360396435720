import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '#/redux/reducer';

type ModalState = {
  isModalActive: boolean;
};

const name = 'modal';

const initialState: ModalState = {
  isModalActive: false,
};

export const {
  reducer,
  actions: { setModal },
} = createSlice({
  name,
  initialState,
  reducers: {
    setModal(state = initialState, { payload }: PayloadAction<boolean>) {
      state.isModalActive = payload;
    },
  },
});

let rootSelector: RootSelector<ModalState> = (state: any) => state[name];

export const setRootSelector: SetRootSelector<ModalState> = selector => {
  rootSelector = selector;
};

export const getIsModalActive = (state: RootState) =>
  rootSelector(state).isModalActive;
