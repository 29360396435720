export type Key = {
  text: string;
  value: string;
  nav: XDKNav;
  row: number;
  altText?: string;
  altValue?: string;
  special?: boolean;
  numbersNav?: any;
  specialNav?: any;
  otherCharsNav?: any;
  icon?: Icon;
  customSize?: number;
  accessibilityLabel?: string;
};

type Icon = {
  src: string;
};

export const createHorizontalKeyNav = (keys: Key[]): Key[] => {
  const keysNavigation: Key[] = [];

  for (let i = 0; i < keys.length; i += 1) {
    const newElement = { ...keys[i] };

    if (i === keys.length - 1) {
      newElement.nav.nextleft = keys[i - 1].nav.id;
    } else if (i === 0) {
      newElement.nav.nextright = keys[i + 1].nav.id;
    } else {
      newElement.nav.nextleft = keys[i - 1].nav.id;
      newElement.nav.nextright = keys[i + 1].nav.id;
    }

    keysNavigation.push(newElement);
  }

  return keysNavigation;
};
