import { useCallback, memo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { withFocus } from '@accedo/vdkweb-navigation';

import FocusDiv from '#/components/FocusDiv/FocusDiv';
import { useImage } from '#/hooks';
import { composeBannerLabel } from '#/utils/accessibility';

import styles from './banner.scss';

type Props = {
  data?: any;
};

const Banner = ({ nav, isFocused, data }: Props & WithFocusProps) => {
  const history = useHistory();
  const imageLoaded = useImage(data?.backgroundImage);

  const onClick = useCallback(() => {
    history.push(`/promotional/${data?.page}`);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.title}
        aria-label={composeBannerLabel(data?.displayText)}
      >
        {data?.displaytext}
      </div>
      <FocusDiv
        nav={nav}
        onClick={onClick}
        className={classNames(styles.banner, {
          [styles.focused]: isFocused,
          [styles.loaded]: imageLoaded,
        })}
        style={{
          backgroundImage: `url('${data?.backgroundImage}')`,
        }}
        aria-hidden="true"
      />
    </div>
  );
};

export default withFocus(memo(Banner));
